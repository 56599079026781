.alt-table-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .alt-table {
    height: 100%;
    border: none;

    .rt-table {
      .rt-thead {
        .rt-tr {
          border-bottom: 1px solid lightgray;
          padding-right: 16px;

          .rt-th {
            height: 24px;
            padding: 0;
            padding-left: 10px;
            border-right: 1px solid rgba(0, 0, 0, 0.05);
            text-align: left;
            -webkit-font-smoothing: antialiased;
            font: 600 14px/24px 'TradeGothic', 'Helvetica Neue', Helvetica,
              Arial, sans-serif;
            color: #3c3c3c;

            > div {
              position: relative;
              display: inline-block;
              padding-right: 15px;
              line-height: 24px;

              &.rt-resizer {
                padding: 0;
                position: absolute;
                top: 0;
                right: 0;
                width: 10px;
                height: 100%;
              }
            }
            &.selector-header,
            &.chevron-header,
            &:last-child {
              border-right: none;
            }
            &.selector-header {
              padding: 0 4px;

              .checkbox {
                top: -4px;
                position: relative;
                width: 28px;
                height: 28px;
                margin-top: 2px;
                padding-top: 8px;
                border-radius: 25px;
                text-align: center;
                cursor: pointer;
                background: transparent;
                transition: background 0.075s linear;

                .fa {
                  display: block;
                  height: 12px;
                  width: 12px;
                  margin: 0 auto;
                  font-size: 10px;
                  line-height: 11px;
                  border-radius: 3px;
                  border: 1px solid #9a9a9a;
                  color: transparent; // deselected
                  background: white;
                }
                &:hover {
                  background: #f1f1f1;
                }
              }
            }
            &.-sort-asc,
            &.-sort-desc {
              box-shadow: none;

              > div:first-child {
                position: relative;

                &:before {
                  content: '';
                  position: absolute;
                  top: 50%;
                  right: 2px;
                  width: 0;
                  height: 0;
                  border-left: 4px solid transparent;
                  border-right: 4px solid transparent;
                  border-top: 4px solid gray;
                }
                &:after {
                  content: '';
                  position: absolute;
                  top: calc(50% - 4px);
                  right: 5px;
                  width: 2px;
                  height: 4px;
                  background: gray;
                }
              }
            }
            &.-sort-desc {
              > div:first-child {
                &:before {
                  top: calc(50% - 4px);
                  transform: rotate(180deg);
                }
                &:after {
                  top: 50%;
                }
              }
            }
          }
        }
      }
      .rt-tbody {
        overflow-y: scroll;
        overflow-x: hidden;

        &::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 16px;
          background: #e4e4e4;

          &:horizontal {
            display: none;
          }
        }
        &::-webkit-scrollbar-track:vertical {
          width: 16px;
        }
        &::-webkit-scrollbar-thumb {
          width: 8px;
          border-radius: 16px;
          background-color: #ffffff;
          border: 4px solid #e4e4e4;
        }
        .rt-tr-group {
          flex: 0 0;
          border: none;

          .rt-tr {
            border-bottom: 1px solid #ececec;
            background: white;

            &.-even {
              background: #f7f7f7;
            }
            &:hover:not(.-padRow) {
              background: #eaeaea;
            }
            .rt-td {
              position: relative;
              padding: 0;
              padding-right: 20px;
              padding-left: 10px;
              text-align: left;
              font: 500 12px/32px sans-serif;

              &.chevron-col {
                box-sizing: border-box;
                display: block;
                width: 32px;
                height: 100%;
                padding: 0;
                padding-right: 45px;
                border-right: none;

                .chevron {
                  display: block;
                  width: 32px;
                  height: 100%;
                  padding-right: 30px;
                  padding-left: 20px;
                  cursor: pointer;

                  &:hover {
                    background: rgba(0, 0, 0, 0.02);
                  }
                }
              }
              .rt-expander {
                &::after {
                  border-top: 5px solid gray;
                }
              }
            }
            &.-padRow {
              opacity: 0.5;
              background: white;

              .rt-td {
                span {
                  position: relative;
                  display: inline-block;
                  width: 100%;
                  max-width: 200px;
                  height: 10px;
                  top: 12px;
                  border-radius: 5px;

                  opacity: 0;
                  transition: opacity 100ms;

                  // shine
                  background-image: linear-gradient(
                    90deg,
                    #d8d8d8 0px,
                    #e2e2e1 40px,
                    #d8d8d8 80px
                  );
                  background-size: 600px;
                  animation: shiny 2.8s infinite ease-out;

                  @keyframes shiny {
                    0%,
                    40% {
                      background-position: -200px;
                    }
                    100% {
                      background-position: 300px;
                    }
                  }
                }
              }
            }
          }
          // fade placeholders out
          @for $i from 1 through 100 {
            &:nth-child(#{$i}) {
              .rt-tr.-padRow {
                span {
                  transition-delay: $i * 10ms;
                }
              }
            }
          }
        }
      }
    }
    .rt-noData {
      top: calc(15% + 40px);
      padding: 10px 30px;
      border-radius: 5px;
      border: 1px solid lightgray;
      text-transform: uppercase;
      font: 600 14px/24px 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
        sans-serif;
    }
    &.alt-rows-loading {
      .rt-table {
        .rt-tbody {
          .rt-tr-group {
            opacity: 0;
            animation: opacityReveal 0.2s ease-in;
            animation-fill-mode: forwards;
            animation-iteration-count: 1;

            @keyframes opacityReveal {
              from {
                opacity: 0;
              }
              to {
                opacity: 1;
              }
            }

            @for $i from 2 through 100 {
              &:nth-child(#{$i}) {
                animation-delay: $i * 10ms;
              }
            }

            .rt-tr {
              &.-padRow {
                .rt-td {
                  text-overflow: clip;

                  span {
                    opacity: 0.75;
                    min-width: 10px;
                  }
                }
              }
            }
          }
        }
      }
      .rt-noData {
        display: none;
      }
    }
    &.alt-selectable {
      .rt-table {
        .rt-thead {
          .rt-tr {
            .rt-th {
              &.selector-header {
                padding: 0 4px;

                .checkbox {
                  top: -4px;
                  position: relative;
                  width: 28px;
                  height: 28px;
                  margin-top: 2px;
                  padding-top: 8px;
                  border-radius: 25px;
                  text-align: center;
                  cursor: pointer;
                  background: transparent;
                  transition: background 0.075s linear;

                  .fa {
                    display: block;
                    height: 12px;
                    width: 12px;
                    margin: 0 auto;
                    font-size: 10px;
                    line-height: 11px;
                    border-radius: 3px;
                    border: 1px solid #9a9a9a;
                    color: transparent; // deselected
                    background: white;
                  }
                  &:hover {
                    background: #f1f1f1;
                  }
                }
              }
              &:nth-child(2) {
                padding-left: 0;
              }
            }
          }
        }
        .rt-tbody {
          .rt-tr-group {
            .rt-tr {
              .rt-td {
                &.selector-col {
                  padding: 0 4px;
                  border-right: none;

                  .checkbox {
                    position: relative;
                    width: 28px;
                    height: 28px;
                    margin-top: 2px;
                    padding-top: 8px;
                    border-radius: 25px;
                    text-align: center;
                    cursor: pointer;
                    background: transparent;
                    transition: background 0.075s linear;

                    .fa {
                      display: block;
                      height: 12px;
                      width: 12px;
                      margin: 0 auto;
                      font-size: 10px;
                      line-height: 11px;
                      border-radius: 3px;
                      border: 1px solid #9a9a9a;
                      color: transparent; // deselected
                      background: white;
                    }
                    &:hover {
                      background: lightgray;
                    }
                  }
                }
                &:nth-child(2) {
                  padding-left: 0;
                }
              }
              &.row-selected {
                .rt-td {
                  &.selector-col {
                    .checkbox {
                      .fa {
                        color: #9a9a9a;
                      }
                    }
                  }
                }
              }
              &.-padRow {
                .rt-td {
                  &.selector-col {
                    span {
                      display: block;
                      margin: 0 auto;
                      width: 12px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    // table level because col headers dont re-render on on change.
    &.alt-all-selected
      .rt-table
      .rt-thead
      .rt-tr
      .rt-th.selector-header
      .checkbox
      .fa {
      color: #9a9a9a;
    }
    .pagination-bottom {
      height: 42px;
      padding: 4px 5px;
      border-top: 1px solid lightgray;

      .-pagination {
        height: 32px;
        padding: 0;
        border: 0;
        box-shadow: none;

        .-btn {
          height: 32px;
          padding: 7px 20px;
          border-radius: 5px;
          border: 1px solid lightgray;
          font: 600 16px/1 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
            sans-serif;
          background: white;
          color: #323232;

          &:not([disabled]):hover {
            background: #f5f5f5;
            color: #222222;
          }
        }
        .-center {
          order: 1;
          height: 32px;
          margin: 0;
          padding: 0;
          justify-content: start;
          font: 400 14px/16px sans-serif;

          .-pageInfo {
            margin: 0 5px;
            padding: 0;

            input {
              height: 28px;
            }
          }
          .-pageSizeOptions {
            margin: 0 5px;
            margin-left: 10px;
            padding: 0;

            select {
              height: 28px;
              padding: 5px;
            }
          }
        }
        .-previous {
          order: 2;
          flex: 0 0 60px;
          margin-left: 5px;
        }
        .-next {
          order: 3;
          flex: 0 0 60px;
          margin-left: 5px;
        }
      }
    }
  }
}
