.dash-main.build {
  position: relative;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: white;

  .build-header {
    flex: 0 0;
    position: relative;
    padding: 0 20px;
    padding-top: 15px;
    padding-bottom: 8px;
    border-bottom: 1px solid #d6d6d6;
    background: white;

    display: flex;
    flex-direction: row;
    text-decoration: capitalize;

    .list-title {
      position: relative;
      flex: 1 1;
      padding: 0;
      padding-top: 3px;
      white-space: nowrap;
      font: 600 16px/1 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
        sans-serif;

      .list-subtitle {
        display: block;
        padding-top: 2px;
        font: 600 12px/1 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
          sans-serif;
        color: gray;
      }
      a {
        text-decoration: underline;
      }
    }
    .alt-refresh-button {
      font-size: 14px;

      i.fa {
        position: relative;
        left: -4px;
        font-size: 14px;
      }
      &.alt-button-disabled {
        i.fa {
          animation: spinner 0.8s linear infinite;

          @keyframes spinner {
            to {
              transform: rotate(360deg);
            }
          }
        }
      }
    }
  }

  .build-info {
    position: relative;
    flex: 1 1;
    width: 100%;
    background: white;

    .build-info-inner {
      // dont scroll the whole page, only the content
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #f6f6f6;

      .build-image {
        padding: 20px;
        border-bottom: 1px solid lightgray;
        background: white;

        .build-image-title {
          padding-bottom: 6px;
          text-transform: uppercase;
          font: 600 16px/1 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
            sans-serif;
        }
        .build-image-copy {
          position: relative;
          display: flex;
          flex-direction: row;
          border-radius: 5px;
          border: 1px solid lightgray;

          p {
            flex: 1 1;
            padding: 8px 12px;
            padding-right: 5px;
            font: 500 14px/1 sans-serif;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}
