.import-settings-section {
  max-width: 660px;
  height: 100%;

  .default-message {
    font: 600 12px/1 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
      sans-serif;
  }

  .extra-attribute-table {
    font: 600 12px/1 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
      sans-serif;

    .alt-table-wrapper {
      position: relative;
      padding-top: 10px;
      padding-bottom: 20px;
      width: 660px;

      .alt-table .rt-table .rt-tbody {
        overflow-y: hidden;
      }
    }
  }
}
