.alt-page-wrapper {
  .dash-main.config {
    position: relative;
    flex: 1 1;
    height: 100%;
    background: white;

    .config-inner {
      // dont scroll the whole page, only the content
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding-bottom: 20px;
    }
    .config-down,
    .config-up {
      position: fixed;
      bottom: 20px;
      right: 108px;
      border: 1px solid lightgray;
      z-index: 800;

      padding: 8px 10px;
      font-size: 18px;
      line-height: 18px;
      color: gray;
      cursor: pointer;
      background: white;

      &:hover {
        background: lightgray;
      }
    }
    .config-up {
      right: 72px;
    }
    .config-down {
      right: 36px;
    }
    .config-title {
      padding: 40px;
      padding-bottom: 20px;
      font: 600 28px/1 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
        sans-serif;
    }
    .config-description {
      max-width: 960px;
      padding: 0 40px;
      font: 500 16px/1.2 sans-serif;
    }
    .config-error {
      margin: 20px 40px;
      font: 500 14px/1.2 monospace;
      color: #e74c3c;
    }
    .config-action-bar {
      display: flex;
      flex-direction: row;
      padding: 10px 40px;

      .config-filter-label {
        padding: 10px 0;
        padding-right: 10px;
        font: 400 14px/1 sans-serif;
      }
      .config-filter {
        position: relative;
        flex: 0 0;
        display: flex;
        flex-direction: row;
        margin-right: auto;

        .config-filter-option {
          padding: 10px 14px;
          font: 400 14px/1 sans-serif;
          border-right: 1px solid white;
          background: #f1f1f1;
          cursor: pointer;

          &.active {
            background: #dddddd;
          }
          &:first-child {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }
          &:last-child {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            border-right: none;
          }
        }
      }
    }
  }
}
