.alt-page-wrapper {
  .dash-main.metrics {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    background: #f6f6f6;

    .metrics-main {
      flex: 1 1;

      &.metrics-table {
        flex: 1 1;
        position: relative;
        width: 100%;
        background: white;

        .rt-tr.-expander-disabled {
          .rt-td.rt-expandable {
            pointer-events: none;

            .rt-expander {
              opacity: 0.25;
            }
          }
        }
        .metrics-sub-table {
          position: relative;
          width: 100%;
          height: 300px;
          border-bottom: 1px solid #ececec;
          border-left: 35px solid #ececec;

          .alt-table-wrapper {
            .alt-table {
              .rt-table {
                .rt-tbody {
                  overflow-y: hidden;
                }
              }
            }
          }
        }
        .table-chart-wrapper {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    .metrics-header {
      display: flex;
      flex-direction: row;
      flex: 0 0;
      position: relative;
      padding: 10px 20px;
      border-bottom: 1px solid lightgray;
      background: white;

      .metrics-header-title {
        position: relative;
        flex: 0 1;
        margin-right: auto;
        padding: 0;
        padding-top: 3px;
        white-space: nowrap;
        font: 600 16px/1 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
          sans-serif;

        .metrics-header-subtitle {
          display: block;
          padding-top: 2px;
          font: 600 12px/1 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
            sans-serif;
          color: gray;
        }
      }
      .metrics-service,
      .metrics-period,
      .metrics-range {
        flex: 0 1 110px;
        margin-left: 10px;
        padding: 0;

        .alt-field-label {
          display: none;
        }
        .alt-select {
          min-width: 110px;
        }
      }
      .metrics-service {
        flex: 0 1 165px;
      }
      .metrics-range {
        #alt-date-input {
          border-radius: 5px 0 0 5px;
          border-right-width: 0;
        }
      }
      .metrics-period {
        flex: 0 1 70px;
        margin-left: 0;

        .alt-select {
          min-width: 70px;

          .select__control {
            border-radius: 0 5px 5px 0;

            &.select__control--menu-is-open {
              border-bottom-right-radius: 0;
            }
          }
        }
      }
      .alt-back-button,
      .alt-forward-button {
        height: 32px;
        padding: 8px 12px;
        font-size: 14px;
        color: #222222;
      }
      .alt-back-button {
        border-radius: 5px 0 0 5px;
      }
      .alt-forward-button {
        border-radius: 0 5px 5px 0;
        border-left-width: 0;
        margin-left: 0;
      }
      .alt-refresh-button {
        flex: 0 0 40px;
        height: 32px;
        padding: 8px 12px;
        font-size: 14px;

        i.fa {
          position: relative;
          font-size: 14px;
        }
        &.alt-button-disabled {
          i.fa {
            animation: spinner 0.8s linear infinite;

            @keyframes spinner {
              to {
                transform: rotate(360deg);
              }
            }
          }
        }
      }
    }

    .metric-chart-row {
      position: relative;
      height: 161px;
      background: white;
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid lightgray;

      .metric-chart {
        position: relative;
        flex: 1 1;
        height: 160px;

        &.small {
          height: 80px;
        }
      }
    }
    .metric-error {
      padding: 20px;
      font: 500 14px/1.2 monospace;
      color: #e74c3c;
    }
  }
}
