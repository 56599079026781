.alt-page-wrapper {
  overflow: auto;
  .dash-main.costs {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    height: 100%;

    .costs-header {
      flex: 0 0;
      position: relative;
      padding: 10px 20px;
      border-bottom: 1px solid #d6d6d6;
      background: white;

      display: flex;
      flex-direction: row;
      text-decoration: capitalize;

      .list-title {
        position: relative;
        flex: 0 1;
        margin-right: auto;
        padding: 0;
        padding-top: 3px;
        white-space: nowrap;
        font: 600 16px/1 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
          sans-serif;

        .list-subtitle {
          display: block;
          padding-top: 2px;
          font: 600 14px/1 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
            sans-serif;
          color: gray;
        }
      }

      .alt-refresh-button {
        flex: 0 0 40px;
        height: 34px;
        padding: 8px 12px;
        font-size: 14px;

        i.fa {
          position: relative;
          font-size: 14px;
        }

        &.alt-button-disabled {
          i.fa {
            animation: spinner 0.8s linear infinite;

            @keyframes spinner {
              to {
                transform: rotate(360deg);
              }
            }
          }
        }
      }
    }

    .costs-main {
      width: 100%;
      background-color: white;

      .costs-error {
        padding: 20px;
        font: 500 14px/1.2 monospace;
        color: #e74c3c;
      }

      .cost-row {
        padding: 10px 20px;
        border-top: 1px solid lightgray;
        display: flex;
        flex-wrap: wrap;
        margin-left: -15px;
        margin-right: -15px;

        .cost-col-6 {
          flex: 0 0 50%;
          max-width: 50%;
          padding-left: 15px;
          padding-right: 15px;
          position: relative;
          width: 100%;
        }
        .cost-col-12 {
          flex: 0 0 100%;
          max-width: 100%;
          padding-left: 15px;
          padding-right: 15px;
          position: relative;
          width: 100%;
        }
        .cost-item-title {
          margin-bottom: 5px;
          font: 600 16px / 1 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
            sans-serif;
          .cost-item-subtitle {
            display: block;
            padding-top: 2px;
            font: 600 14px/1 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
              sans-serif;
            color: gray;
          }
        }

        .cost-item-value {
          display: flex;
          -webkit-box-pack: center;
          justify-content: center;
          flex-wrap: wrap;
          text-align: center;

          svg {
            filter: drop-shadow(gray 0px 5px 5px);
            text:before {
              content: '$';
            }
            p {
              font: 600 16px / 1 'TradeGothic', 'Helvetica Neue', Helvetica,
                Arial, sans-serif;
            }
          }
        }
      }

      .costs-cap {
        font: 500 14px/1.2 monospace;
        padding: 10px;
        padding-left: 20px;
        padding-bottom: 30px;
      }
    }
  }
}
