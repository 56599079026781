.alt-text-input {
  position: relative;

  input {
    width: 100%;
    padding: 8px 12px;
    border-radius: 5px;
    border: 1px solid lightgray;
    font: 400 14px/16px sans-serif;

    &:disabled {
      background: #f3f3f3;
      cursor: not-allowed;
    }
  }
}
