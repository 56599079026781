.alt-date-single {
  position: relative;
  border-radius: 5px;

  .SingleDatePickerInput {
    border-radius: 5px;
    background: transparent;

    .DateInput {
      height: 32px;
      border-radius: 5px;
      background: transparent;

      #alt-date-input {
        height: 32px;
        padding: 8px 10px;
        background: none;
        border-radius: 5px;
        border: 1px solid lightgray;
        font: 600 16px/1 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
          sans-serif;

        &.DateInput_input__focused {
          background: #eaeaea;
        }
        &.DateInput_input__disabled {
          cursor: not-allowed;
          background: #eaeaea;
        }
      }
    }
    .DateInput_fang {
      display: none;
    }
    .SingleDatePicker_picker {
      .DayPicker {
        > div {
          position: relative;
          display: flex;
          flex-direction: row;
          height: 100%;
          width: 100%;
        }
        &.DayPicker__withBorder {
          border-radius: 5px;
        }
        .DayPicker_wrapper__horizontal {
          position: relative;
          display: block;
          flex: 1 1;

          .DayPickerNavigation {
            .DayPickerNavigation_button {
              top: -1px;
              border-radius: 0;
            }
            .DayPickerNavigation_leftButton__horizontalDefault {
              left: -1px;
              border-top-left-radius: 5px;
            }
            .DayPickerNavigation_rightButton__horizontalDefault {
              right: -1px;
            }
          }
        }
        .DayPicker_calendarInfo__horizontal {
          position: relative;
          display: block;
          flex: 0 0 120px;

          .time-sidebar {
            position: relative;
            height: 100%;
            width: 120px;
            display: flex;
            flex-direction: column;

            .tz-selector {
              flex: 0 0;
              padding: 0;

              .alt-field-label {
                display: none;
              }
              .alt-select {
                .select__control {
                  border-top: 0;
                  border-radius: 0 5px 0 0;
                }
              }
            }
            .time-selector-wrapper {
              position: relative;
              width: 100%;
              flex: 1 1;
            }
            .time-selector-abs {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              border-left: 1px solid lightgray;
            }
            .time-selector {
              position: relative;
              display: block;
              width: 100%;
              height: 100%;
              overflow-y: scroll;
              padding: 3px 0;

              .time-selector-time {
                padding: 2px 25px;
                text-align: center;

                &:hover {
                  cursor: pointer;
                  background: lightgray;
                }
                &.time-active {
                  background: lightgray;
                }
              }
            }
          }
        }
        .DayPicker_weekHeader {
          top: 34px;

          .DayPicker_weekHeader_ul {
            .DayPicker_weekHeader_li {
              font-weight: 600;
            }
          }
        }
        .CalendarMonth {
          padding: 0;

          .CalendarMonth_caption {
            margin-bottom: 26px;
            padding-top: 7px;
            padding-bottom: 6px;
            border-bottom: 1px solid lightgray;
            font: 600 18px/1 'Helvetica Neue Regular', Helvetica, Arial,
              sans-serif;
          }
          .CalendarMonth_table {
            border-collapse: collapse;

            .CalendarDay {
              vertical-align: middle;
              font-family: 'Helvetica Neue Regular', Helvetica, Arial,
                sans-serif;

              &.CalendarDay__selected {
                background: #00a699;
                border: 1px double #00a699;
              }
              &.CalendarDay__selected_span {
                background: #66e2da;
                border: 1px double #33dacd;
              }
            }
          }
        }
      }
    }
  }
}
