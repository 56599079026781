#notification-contact {
  .notification-config-row {
    display: flex;
    max-width: calc(660px + 80px);

    > :not(:first-child) {
      margin-left: 5px;
    }

    .notification-config-service,
    .notification-config-contact,
    .notification-config-event-type,
    .notification-config-meta_data,
    .notification-config-delete {
      display: flex;
      padding-bottom: 5px;

      .alt-text-input > input {
        height: 32px;
      }
      .alt-field-error {
        bottom: -8px;
        white-space: nowrap;
      }
    }

    .notification-config-contact {
      flex: 1 1 auto;
    }

    .notification-config-service {
      flex: 0 0 170px;

      .alt-select {
        min-width: 120px;
      }
    }
    .notification-config-event-type {
      flex: 1 1 200px;

      .alt-select {
        min-width: 170px;
      }
    }
    .notification-config-delete {
      flex: 0 0;
      height: 32px;
      margin-top: 29px;
      padding: 6px 20px;
      line-height: 12px;
    }
  }
}
