.alt-form-field {
  position: relative;
  display: block;
  width: 100%;
  padding: 10px 0;

  .alt-field-label {
    padding-bottom: 5px;
    font: 600 14px/1 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
      sans-serif;

    .alt-field-label-required {
      position: relative;

      &:before {
        position: absolute;
        top: -2px;
        right: -7px;
        content: '*';
        color: red;
      }
    }
  }
  .alt-field-detail {
    padding-bottom: 6px;
    font: 400 14px/1 'Helvetica Neue Regular', Helvetica, Arial;

    a {
      text-decoration: underline;
    }
  }
  .alt-field-error {
    position: absolute;
    bottom: -2px;
    left: 0;
    font: 400 12px/1 sans-serif;
    color: red;
  }
}
