.chart-legend {
  position: relative;
  flex: 0 0 250px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
  padding-left: 9px;
  padding-top: 20px;
  overflow: hidden;

  .chart-legend-header {
    position: relative;
    flex: 0 0;
    display: flex;
    flex-direction: row;
    padding-left: 7px;
    padding-bottom: 3px;

    .chart-legend-title {
      margin-right: auto;
      padding-top: 4px;
      font: 600 16px/1 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
        sans-serif;
      color: gray;
    }
  }
  .chart-legend-body {
    flex: 1 1;
    overflow-y: scroll;

    .chart-legend-item {
      position: relative;
      padding: 2.5px 7px;
      font: 500 12px/1 sans-serif;
      white-space: nowrap;

      &.item-selected {
        padding: 1.5px 6px;
        border: 1px solid lightgray;
        border-right: none;
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
        background: #f8f8f8;
      }
      .chart-legend-item-dot {
        position: relative;
        display: inline-block;
        top: 0px;
        width: 8px;
        height: 8px;
        margin-right: 5px;
        border-radius: 25px;
        background: lightgray;
      }
    }
  }
}
