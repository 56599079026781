.alt-status-list {
  .alt-list-item {
    position: relative;
    padding: 10px 25px 9px 25px;

    &:not(:last-child) {
      border-bottom: 1px solid lightgray;
    }
    .alt-list-item-name {
      white-space: nowrap;
      font: 500 12px/12px sans-serif;

      &.alt-list-item-strike {
        text-decoration: line-through;
      }
      span {
        color: gray;
      }
      .fa {
        display: inline-block;
        margin-right: 5px;
        text-align: center;
        line-height: 12px;
        font-size: 12px;

        &.fa-circle,
        &.fa-circle-o-notch,
        &.fa-exclamation-triangle {
          color: lightgray;
        }
        &.fa-circle-o-notch {
          animation: rotation 1.4s infinite linear;

          @-webkit-keyframes rotation {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(359deg);
            }
          }
        }
        &.fa-check-circle {
          color: #27ae60;
        }
        &.fa-exclamation-circle {
          color: #e74c3c;
        }
      }
    }
    .alt-list-item-error {
      position: relative;
      top: 2px;
      padding-left: 30px;
      font: 500 12px/12px sans-serif;
      color: #e74c3c;
    }
  }
}
