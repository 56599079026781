.alt-creatable-select-input {
  &.copy-button {
    display: flex;

    .creatable-select-container {
      width: 94%
    }

    .copy-to-clipboard-button {
      min-height: 32px;
      height: 32px;
      padding: 7px;
    }
  }

  position: relative;
  font: 600 14px/1 'Trade Gothic',
  'Helvetica Neue Regular',
  Helvetica,
  Arial,
  sans-serif;

  input {
    width: 100%;
    padding: 8px 12px;
    border-radius: 5px;
    border: 1px solid lightgray;

    &:disabled {
      background: #f3f3f3;
      cursor: not-allowed;
    }
  }

  .creatable-select__control {
    min-height: 32px;
    height: 32px;
    border: 1px solid lightgray;
    border-radius: 5px;
    transition: none;

    .creatable-select__value-container {
      padding-left: 10px;

      .creatable-select__single-value,
      .creatable-select__placeholder {
        margin-left: 3px;
        color: #c7c7c7;
      }

      &.creatable-select__value-container--has-value {

        .creatable-select__single-value,
        .creatable-select__placeholder {
          color: #404040;
        }
      }

      .creatable-select__input {
        height: 16px;
        padding: 0;
      }
    }

    .creatable-select__indicators {
      padding: 0;

      .creatable-select__indicator {
        padding: 4px;
      }

      .creatable-select__indicator-separator {
        display: none;
      }
    }

    &.creatable-select__control--is-focused {
      border: 1px solid gray;
      box-shadow: none;
    }

    &.creatable-select__control--menu-is-open {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .creatable-select__menu {
    margin-top: 0;
    border: 1px solid gray;
    border-top: none;
    border-radius: 0 0 5px 5px;
    overflow: hidden;

    .creatable-select__menu-list {
      padding: 0;

      .creatable-select__option {
        &.creatable-select__option--is-focused {
          background-color: #f4f4f4;
        }

        &.creatable-select__option--is-selected {
          background-color: #dfdfdf;
          color: black;
        }
      }

      .creatable-select__menu-notice--no-options {
        padding-left: 10px;
        text-align: left;
      }
    }
  }
}