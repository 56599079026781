.alt-page-wrapper.create-wrapper {
  display: flex;
  flex-direction: row;

  .create-sidebar {
    position: relative;
    height: 100%;
    flex: 0 0 440px;
    padding: 40px;
    border-right: 1px solid #d6d6d6;
    background: white;
    z-index: 600;

    .loading-spinner {
      width: 80%;
      margin: auto;

      h4 {
        text-align: center;
        padding: 30px 0px 10px 0px;
        font-style: italic;
      }

      .spinner {
        top: 0px;
      }
    }

    .create-sidebar-title {
      padding-bottom: 20px;
      font: 600 20px/1 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
        sans-serif;
    }
    .create-sidebar-subtitle {
      padding-bottom: 10px;
      font: 500 16px/1.4 sans-serif;
    }
    .alt-button {
      margin-left: 0;
    }
  }
  .create-main {
    flex: 1 1;
    height: 100%;
    width: 100%;
    max-width: 100%;
    padding: 0;
    padding-top: 40px;
    background: white;

    .setting-fields {
      // opacity: 0.5;
      // background-color: gray;
    }

    .create-form-section {
      padding: 0 40px;
      padding-top: 10px;
      padding-bottom: 30px;
      border-top: 1px solid lightgray;

      .alt-text-input {
        display: flex;
        align-items: center;

        .alt-select {
          flex: 1 1 auto;
        }
      }

      .alt-toggle-input {
        display: flex;
        align-items: center;
      }

      .alt-kv-input {
        display: flex;
        align-items: center;

        .alt-kv-tags {
          flex: 1 1 auto;
        }
      }

      &.section-notifications {
        .split-group {
          > :nth-child(1) {
            flex: 1 1;

            .alt-text-input {
              input {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                padding: 7px 12px;
              }
            }
          }
          > :nth-child(2) {
            flex: 0 0 100px;

            .alt-select {
              min-width: 110px;

              .select__control {
                border-radius: 0;
                border-left: none;
                border-right: none;
              }
            }
          }
        }
      }
      .create-form-section-title {
        position: relative;
        display: inline-block;
        top: -20px;
        font: 600 20px/1 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
          sans-serif;
        text-transform: uppercase;
        background: white;
      }
      .create-form-section-description {
        position: relative;
        top: -5px;
        max-width: 660px;
        font: 400 14px/1 'Helvetica Neue Regular', Helvetica, Arial;

        a {
          text-decoration: underline;
        }
      }
      .create-form-section-inner {
        max-width: 660px;

        .cluster-name-aside {
          position: absolute;
          top: 1px;
          right: 0;
          padding: 8px 15px;
          font: 400 14px/16px sans-serif;
          color: gray;

          span {
            position: relative;
            padding-right: 5px;
            animation: text-shake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
            color: red;

            @keyframes text-shake {
              10%,
              90% {
                left: -1px;
              }
              20%,
              80% {
                left: 2px;
              }
              30%,
              50%,
              70% {
                left: -4px;
              }
              40%,
              60% {
                left: 4px;
              }
            }
          }
        }

        .cluster-name-aside-tooltip {
          position: absolute;
          top: 1px;
          right: 35px;
          padding: 8px 15px;
          font: 400 14px/16px sans-serif;
          color: gray;

          span {
            position: relative;
            padding-right: 5px;
            animation: text-shake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
            color: red;

            @keyframes text-shake {
              10%,
              90% {
                left: -1px;
              }
              20%,
              80% {
                left: 2px;
              }
              30%,
              50%,
              70% {
                left: -4px;
              }
              40%,
              60% {
                left: 4px;
              }
            }
          }
        }

        .split-group {
          display: flex;
          flex-direction: row;

          .alt-form-field {
            padding-bottom: 5px;

            .alt-field-error {
              position: relative;
            }
          }
          .alt-button {
            height: 32px;
            margin-left: 0;
            margin-top: 29px;
            padding: 8px 15px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;

            i {
              position: relative;
              top: -1px;
            }
          }
          &:not(:first-child) {
            .alt-field-label {
              display: none;
            }
            .alt-form-field {
              padding-top: 0;
            }
            .alt-button {
              margin-top: 0;
            }
          }
        }
        .list-add-button {
          margin-left: 0;
        }
      }
      .create-form-section-buttons {
        display: flex;
        flex-direction: row-reverse;
      }
    }
  }
  .ad-group-field {
    .ad-group-option {
      position: relative;
      padding: 8px 10px;

      .fa {
        position: absolute;
        top: 0;
        right: 8px;
        line-height: 30px;
        font-size: 10px;
        color: #b7b7b7;
      }
    }
    // react-select override
    .alt-select {
      .select__control {
        .ad-group-option {
          padding: 8px 0;

          .fa {
            right: 3px;
          }
        }
        .select__value-container {
          padding: 0 8px;

          .select__single-value {
            width: 100%;
          }
        }
      }
      .select__menu {
        .select__menu-list {
          .select__option {
            padding: 0;
          }
        }
      }
    }
  }
  .tagguid-field {
    .tagguid-option {
      .tagguid-option-label {
        font: 600 12px/1 'Trade Gothic', 'Helvetica Neue Regular', Helvetica,
          Arial, sans-serif;
      }
      .tagguid-option-value {
        font: 400 10px/1 sans-serif;
      }
    }
    // react-select override
    .alt-select {
      .select__value-container--menu-is-open {
        z-index: 2000;
      }

      .select__menu {
        .select__menu-list {
          .select__option {
            padding: 4px 12px;
          }
        }
      }
    }
  }
}
