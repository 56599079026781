.alt-form-error {
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 3px;
  border: 1px solid lightgray;
  border-left: 3px solid #fc6100;
  padding: 6px 12px;
  font: 400 16px/1.4 sans-serif;
}
