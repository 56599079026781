.alt-stages {
  position: relative;
  width: 100%;

  .stage {
    position: relative;
    background: white;
    opacity: 0.5;

    .stage-inner {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: row;
      padding: 5px 20px;
      border-bottom: 1px solid lightgray;

      .stage-title {
        position: relative;
        flex: 1 1;
        text-transform: capitalize;
        font: 600 16px/20px 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
          sans-serif;

        .fa-chevron-down {
          position: relative;
          top: -2px;
          padding-left: 4px;
          font-size: 11px;
          color: gray;
          cursor: pointer;
          transition: all 200ms;

          &.closed {
            font-size: 11px;
            top: 0px;
            transform: rotate(-90deg);
          }
        }
      }
      .stage-time {
        flex: 0 0;
        text-align: right;
        font: 500 14px/20px 'Helvetica Neue', Helvetica, Arial, sans-serif;
        white-space: nowrap;
        color: gray;
      }
      .stage-spinner {
        flex: 0 0 20px;
        width: 20px;
        height: 20px;
        padding: 2px;
        margin-right: 5px;
        border-radius: 20px;

        .fa-check {
          display: inline-block;
          position: relative;
          top: -2px;
          left: 2px;
          font-size: 12px;
          line-height: 20px;
          text-align: center;
          color: #8cc14f;
        }
        .fa-times {
          display: inline-block;
          position: relative;
          top: -3px;
          left: 2px;
          font-size: 12px;
          line-height: 20px;
          text-align: center;
          color: #e74c3c;
        }
        .stage-spinning {
          display: inline-block;
          position: relative;
          top: -1px;
          left: 2px;
          border-radius: 50%;
          border: 1px solid #bababa;
          border-top: 1px solid black;
          animation: alt-spinner 0.8s linear infinite;
          height: 12px;
          width: 12px;

          @keyframes alt-spinner {
            to {
              transform: rotate(360deg);
            }
          }
        }
      }
    }
    .stage-steps {
      position: relative;
      background: #f5f5f5;

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background: linear-gradient(to bottom, gray, transparent);
        opacity: 0.15;
      }
      &:after {
        top: auto;
        bottom: 0;
        background: linear-gradient(to top, gray, transparent);
      }
      .stage-step {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid lightgray;
        font: 500 14px/1.2 monospace;
        padding: 5px 20px;
        padding-left: 45px;

        .step-timestamp {
          flex: 0 0 180px;
          color: #000095;
        }
        .step-message {
          flex: 1 1;
        }
      }
    }
  }
  .stage.stage-green,
  .stage.stage-red,
  .stage.stage-gray {
    opacity: 1;
  }
}
