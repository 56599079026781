.chart-stacked {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .chart-wrapper {
    position: relative;
    width: 100%;
    height: 100%;

    // D3 SVG starts here
    .chart {
      .axis {
        .domain {
          fill: none;
          stroke: gray;
          stroke-width: 1;
        }
        .tick {
          fill: none;
          stroke: gray;
          stroke-width: 1;

          text {
            font-family: sans-serif;
            stroke: #222222;
            stroke-width: 0.2;
          }
        }

        &.y-axis {
          .domain {
            // Dont show y-axis bar
            display: none;
          }
          .tick {
            line {
              stroke: #222222;
              stroke-width: 0.2;
              stroke-dasharray: 2, 2;
            }
            &:first-of-type {
              display: none;
            }
          }
        }
        &.y-axis.high-water-mark {
          .tick {
            // 90% high water mark
            &:nth-of-type(3) {
              line {
                stroke: #ee0000;
                stroke-width: 0.5;
                stroke-dasharray: 1 0;
              }
              text {
                display: none;
              }
            }
          }
        }
      }
      .area {
        // fill: #c9e9fe;
      }
      .line {
        // stroke: #49baff;
        stroke-width: 0.8;
        fill: transparent;
      }
    }
  }
}
