.alt-page-wrapper {
  .dash-main.image {
    position: relative;
    flex: 1 1;
    height: 100%;
    background: white;

    .image-inner {
      // dont scroll the whole page, only the content
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding-bottom: 20px;
    }

    .image-base-title {
      padding: 40px;
      padding-bottom: 20px;
      font: 600 28px/1 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
        sans-serif;
    }

    .image-base-description {
      max-width: 960px;
      padding: 0 40px;
      font: 500 16px/1.2 sans-serif;
    }

    .image-base-form {
      flex: 1 1;
      height: 100%;
      width: 100%;
      max-width: 100%;
      padding: 0;
      padding-top: 40px;
      background: white;

      .image-base-form-section {
        padding: 0 40px;
        padding-top: 10px;
        padding-bottom: 30px;
        border-top: 1px solid lightgray;

        .image-base-form-section-title {
          font: 600 20px/1 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
            sans-serif;
          position: relative;
          display: inline-block;
          top: -20px;
          text-transform: uppercase;
          background: white;
        }

        .image-base-form-section-subtitle {
          font: 500 16px/1.2 sans-serif;
          max-width: 720px;
          padding-bottom: 20px;
        }


        .image-base-form-section-body {
          max-width: 720px;
        }
      }

      .image-base-form-image-button {
        display: inline-block;
        margin-left: 0;
      }
    }


  }
}