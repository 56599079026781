.dag-validation {
  max-width: 840px;

  .dag-validation-body {
    background: #f6f6f6;

    .dag-validation-line {
      display: block;
      padding: 4px 25px;
      border-bottom: 1px solid lightgray;
      font: 400 14px/1.2 monospace;

      &:last-child {
        border-bottom: none;
      }
    }
  }
  .dag-validation-error {
    display: block;
    padding: 8px 25px;
    font: 400 14px/1.2 sans-serif;

    .fa.fa-exclamation-triangle {
      padding-right: 5px;
      color: rgba(231, 76, 60, 0.85);
    }
  }
}
.cerberus-alert {
  color: #fc6100;
  font-weight: bold;
}
