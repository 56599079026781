.alt-page-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  background: #f7f6f6;

  --sidebar-width: 240px;

  .page-view-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
}
