#notification-exclude-dags {
  .notification-config-row {
    display: flex;
    max-width: calc(660px + 80px);

    .notification-config-exclude-dags {
      display: flex;
      padding-bottom: 5px;
      flex: 0 0 577px;

      .creatable-select__control {
        height: inherit;
      }
      .alt-field-error {
        bottom: -8px;
        white-space: nowrap;
      }
    }
  }
}
