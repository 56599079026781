.alt-dialogue {
  .dialogue-header {
    padding: 20px 25px;
    padding-bottom: 15px;

    .dialogue-title {
      font: 600 20px/1 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
        sans-serif;
    }
    .dialogue-subtitle {
      padding-top: 10px;
      font: 500 14px/16px sans-serif;

      .fa {
        color: gray;
      }
      a {
        text-decoration: underline;
      }

      ul li {
        margin-left: 20px;
        padding-left: 0;
      }
    }
  }

  .dialogue-body {
    max-height: 260px;
    border-bottom: 1px solid lightgray;
    border-top: 1px solid lightgray;
    overflow-y: auto;
  }
  .dialogue-actions {
    display: flex;
    flex-direction: row;
    padding: 10px 25px;

    > :first-child {
      margin-left: 0;
    }
  }
}
