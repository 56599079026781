.alt-toggle-switch {
  display: inline-flex;
  width: 300px;
  padding-top: 10px;

  .alt-button.toggle-button {
    flex: 1 1 150px;
    margin-left: 0px;

    &.alt-button-disabled {
      background-color: lightgray;
    }

    &.option-one {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }

    &.option-two {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }

    &.checked {
      color: white;
      background-color: #fc6100;
      border-color: transparent;

      &:hover {
        background: #fa5400;
      }
    }
  }
}
