.chart-single {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .chart-title {
    position: absolute;
    top: 20px;
    left: 20px;
    width: calc(100% - 20px);
    text-align: center;
    font: 600 16px/1 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
      sans-serif;
    color: gray;
  }
  .chart-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .chart {
    position: relative;
    width: 100%;
    height: 100%;

    .axis {
      .domain {
        fill: none;
        stroke: gray;
        stroke-width: 1;
      }
      .tick {
        fill: none;
        stroke: gray;
        stroke-width: 1;

        text {
          font-family: sans-serif;
          stroke: #222222;
          stroke-width: 0.2;
        }
      }

      &.y-axis {
        .domain {
          // Dont show y-axis bar
          display: none;
        }
        .tick {
          line {
            stroke: #222222;
            stroke-width: 0.2;
            stroke-dasharray: 2, 2;
          }
          &:first-of-type {
            // line {
            display: none;
            // }
          }
        }
      }
    }
    .area {
      fill: #c9e9fe;
    }
    .line {
      stroke: #49baff;
      stroke-width: 0.8;
      fill: transparent;
    }
  }
}
