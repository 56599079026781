.deprecation-banner {
  position: relative;
  flex: 0 0;
  background: white;
  border-left: 5px solid rgba(231, 76, 60, 0.85);

  .deprecation-banner-inner {
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 6px 8px;
    border-bottom: 1px solid lightgray;

    .deprecation-banner-text {
      flex: 1 1;
      font: 500 14px/1 sans-serif;

      .fa.fa-exclamation-triangle {
        padding-right: 3px;
        color: rgba(231, 76, 60, 0.85);
      }

      a {
        text-decoration: underline;
      }
    }
    .fa-times-circle {
      flex: 0 0;
      cursor: pointer;
      color: lightgray;
    }
  }
}
