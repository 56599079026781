.subtitle-options {
  padding: 20px;

  .help-options {
    display: flex;
    white-space: 4px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 10px;
    margin-bottom: 10px;

    .help-info {
      width: 90%;
    }

    .help-arrow {
      align-self: center;
      margin: auto;
    }
  }

  .help-options:hover {
    border: 1px solid #d6d6d6;
    background-color: #d6d6d6;
  }

  .help-options:last-child {
    margin-bottom: 0px;
  }
}

textarea {
  width: 100%;
}

.support-access-reminder {
  border: 1px solid #ffcc66;
  background-color: #ffffcc;
  margin: 15px 10px;
  padding: 10px;
  font-style: italic;
}

.ae-operator-check {
  .question {
    border: 1px solid #ffcc66;
    background-color: #ffffcc;
    margin: 15px 10px;
    padding: 10px;
    font-style: italic;
  }
}

.slack-success {
  .thanks {
    font-style: italic;
    text-align: center;
  }
}

.button-container {
  display: flex;
  justify-content: flex-end;

  span {
    transition: 300ms all ease;
    opacity: 0;
    will-change: opacity;
    padding: 9px;
    position: absolute;
    left: 45%;
    color: #8cc14f;
  }

  .show {
    opacity: 1;
  }
}

.slack-form {
  .alt-button {
    margin-left: auto;
  }
  .back-button {
    float: left;
  }

  .dags-hidden {
    visibility: hidden;
  }
}
