.alt-button.alt-button {
  flex: 0 0;
  position: relative;
  display: block;
  margin-left: 10px;
  padding: 8px 20px;
  white-space: nowrap;
  border-radius: 5px;
  border: 1px solid lightgray;
  font: 600 16px/1 'TradeGothic', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  background: white;
  color: black;
  cursor: pointer;

  &:hover {
    background: #f5f5f5;
  }
  &.alt-button-primary {
    color: #fff;
    background-color: #fc6100;
    border-color: #fc6100;

    &:hover {
      background: #fa5400;
    }
  }
  &.alt-button-danger {
    color: #cf1f2d;
    border-color: #cf1f2d;
  }
  &.alt-button-borderless {
    border-color: transparent;
  }
  &.alt-button-disabled,
  &.alt-button-locked {
    opacity: 0.75;
    cursor: not-allowed;
    color: gray;

    &:hover {
      background: white;
    }
    &.alt-button-primary {
      color: white;

      &:hover {
        background: lightgray;
      }
    }
  }
  &.alt-button-locked {
    .alt-button-lock-icon {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      line-height: 32px;
      font-size: 16px;
      color: gray;

      &:active {
        animation: alt-button-lock-icon-shake 0.5s
          cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        perspective: 1000px;

        @keyframes alt-button-lock-icon-shake {
          10%,
          90% {
            transform: translate3d(-1px, 0, 0);
          }
          20%,
          80% {
            transform: translate3d(2px, 0, 0);
          }
          30%,
          50%,
          70% {
            transform: translate3d(-4px, 0, 0);
          }
          40%,
          60% {
            transform: translate3d(4px, 0, 0);
          }
        }
      }
    }
    &:hover {
      color: transparent;

      .alt-button-lock-icon {
        display: block;
      }
    }
  }
  &.alt-button-pending {
    color: transparent;
    background: lightgray;

    .alt-button-pending-icon {
      position: absolute;
      top: 9px;
      left: calc(50% - 7px);
      height: 14px;
      width: 14px;
      border-radius: 50%;
      border: 2px solid transparent;
      border-top: 2px solid black;
      animation: alt-button-pending 0.7s
        cubic-bezier(0.5856, 0.0703, 0.4143, 0.9297) infinite;

      @keyframes alt-button-pending {
        to {
          transform: rotate(360deg);
        }
      }
    }
  }
  &.alt-button-dropdown {
    position: relative;
    padding-right: 30px;
    .dropdown-title {
      padding: 0px 3px;
    }
    .dropdown-open {
      color: gray;
    }
    .fa-caret-down {
      margin-left: 5px;
    }
    .dropdown-content {
      margin-top: 5px;
      .dropdown-content-body {
        .dropdown-item {
          border-top: 1px solid black;
          padding: 5px 10px;
          font-size: 14px;
          color: black;
          cursor: pointer;

          &:hover {
            background: #f5f5f5;
          }
        }
      }
    }
  }
}
