.release-notes {
  height: 100%;
  flex: 0 0 380px;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #d6d6d6;
  background: white;
  z-index: 1400;

  .release-notes-header {
    position: relative;
    flex: 0 0 60px;
    display: flex;
    flex-direction: row;
    padding: 12px 20px;
    border-bottom: 1px solid #d6d6d6;
    background: #f5f5f5;

    .release-notes-title {
      flex: 1 1;
      padding: 8px 0;
      font: 600 20px/1 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
        sans-serif;
    }
    .fa-times {
      flex: 0 0;
      padding: 8px 10px;
      border-radius: 5px;
      font-size: 20px;
      line-height: 20px;
      color: gray;
      cursor: pointer;

      &:hover {
        background: lightgray;
      }
    }
  }
  .release-notes-articles {
    flex: 1 1;
    border-bottom: 1px solid #d6d6d6;

    .release-notes-article {
      padding: 20px;
      border-bottom: 1px solid lightgray;

      a {
        text-decoration: underline;
      }
      .release-notes-article-date {
        padding-bottom: 6px;
        font: 500 13px/1 sans-serif;
        color: gray;
      }
      .release-notes-article-title {
        display: inline-block;
        padding-bottom: 8px;
        font: 600 16px/1 sans-serif;
      }
      .release-notes-article-body {
        font: 500 13px/1.4 sans-serif;

        p {
          &:not(:last-child) {
            padding-bottom: 8px;
          }
        }
        ul {
          padding-left: 15px;

          &:not(:last-child) {
            padding-bottom: 8px;
          }

          &.no-bullets {
            padding-left: 0;
            list-style: none;
          }
        }
        img {
          max-width: 300px;
        }
      }
    }
    .release-notes-error {
      margin: 20px;
      font: 500 14px/1.2 monospace;
      color: #e74c3c;
    }
  }
}
