.toasts-container {
  position: absolute;
  top: 55px;
  left: 50%;
  z-index: 900;

  .toast {
    position: relative;
    transform: translateX(-50%);
    margin-bottom: 10px;
    padding: 15px 15px;
    font: 400 14px/1 sans-serif;
    border-left: 4px solid #2ecc71;
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
    background: white;
    cursor: pointer;
    opacity: 1;

    .toast-timer {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 2px;
      width: 100%;
      opacity: 0.7;
      background: lightgray;
      animation: 8s linear 0s 1 growLeft;

      @keyframes growLeft {
        0% {
          width: 0;
        }

        100% {
          width: 100%;
        }
      }
    }

    &.toast-ok {
      border-color: #2ecc71;

      .fa-check-circle {
        padding-right: 10px;
        color: #2ecc71;
      }
    }

    &.toast-info {
      border-color: #3498db;

      .fa-info-circle {
        padding-right: 10px;
        color: #3498db;
      }
    }

    &.toast-error {
      border-color: #e74c3c;

      .fa-exclamation-circle {
        padding-right: 10px;
        color: #e74c3c;
      }
    }

    &.toasts-enter,
    &.toasts-exit-active {
      opacity: 0;
      height: 0;
      overflow: hidden;
    }

    .fa-times {
      margin-left: 10px;
      color: lightgray;
    }
  }
}