.alt-page-wrapper {
  .dash-main.overview {
    width: 100%;
    height: 100%;
    background: white;

    .dash-info {
      width: 100%;
      min-height: 100%;
      display: flex;
      flex-direction: row;

      .dash-col {
        flex: 1 1;
        padding-top: 20px;

        &:not(:last-child) {
          border-right: 1px solid lightgray;
        }
        .dash-error {
          margin: 10px 20px;
          font: 500 14px/1.2 monospace;
          color: #e74c3c;
        }
        .col-title {
          padding-left: 20px;
          font: 600 20px/1 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
            sans-serif;
        }
        .col-subtitle {
          display: inline-block;
          padding-left: 20px;
          padding-bottom: 10px;
          font: 500 14px/1 sans-serif;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
        .col-line {
          padding: 5px 0;
          border-top: 1px solid lightgray;

          .col-line-title {
            padding-left: 20px;
            padding-bottom: 4px;
            font: 600 12px/1 sans-serif;
            color: gray;
            text-transform: capitalize;
          }
          .col-line-detail {
            padding-left: 20px;
            padding-right: 20px;
            font: 400 15px/1 sans-serif;
          }
        }
        .col-line-expandable {
          padding: 0;

          &:hover {
            background: #f7f7f7;
          }
          .col-line-title {
            padding-left: 12px;
            padding-top: 9px;
            padding-bottom: 7px;

            i {
              font-size: 10px;
              line-height: 10px;
            }
          }
        }
        table {
          width: 100%;
          border-spacing: 0;
          font: normal 14px/1.75 'Helvetica Neue', Helvetica, Arial, sans-serif;
          background: white;

          tr {
            width: 100%;

            td {
              width: 99%;
              position: relative;
              border-top: 1px solid lightgray;

              &:first-child {
                width: auto;
                padding-left: 20px;
                padding-right: 30px;
                white-space: nowrap;

                &:before {
                  content: '';
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 10px;
                  height: 100%;
                  background: lightgray;
                }
              }
            }
          }
          tr:hover:not(.cluster-info-table-row-title) {
            background-color: #f7f7f7;
            color: black;
          }
        }
      }
      .dash-provision-col {
        position: relative;
        flex: 0 0 360px;

        .col-line:last-child {
          border-bottom: 1px solid lightgray;
        }
        .col-title {
          padding-bottom: 10px;
        }

        .cluster-id-container {
          display: grid;
          grid-template-columns: auto auto;
          align-items: center;

          .copy-to-clipboard-button {
            margin-left: 1px;
            margin-right: 5px;
            padding: 2px 5px;
            
            i {
              font-size: 12px;
              margin-left: 1px;
              margin-right: 1px;
              padding: 1px;
            }
          }
        }
        .col-line-detail {
          margin: 0; /* Remove default margin */
        }
      }
    }
  }
}
