.settings-form-section {
  padding: 0 40px;
  padding-top: 10px;
  padding-bottom: 30px;
  border-top: 1px solid lightgray;

  .settings-form-section-title {
    position: relative;
    display: inline-block;
    top: -20px;
    font: 600 20px/1 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
      sans-serif;
    text-transform: uppercase;
    background: white;
  }
  .settings-form-section-description {
    position: relative;
    top: -5px;
    max-width: 660px;
    font: 400 14px/1 'Helvetica Neue Regular', Helvetica, Arial;

    a {
      text-decoration: underline;
    }
  }
  .settings-form-section-inner {
    max-width: 660px;

    .split-group {
      display: flex;
      flex-direction: row;

      .alt-form-field {
        padding-bottom: 5px;

        .alt-field-error {
          position: relative;
        }
      }
      .alt-button {
        height: 32px;
        margin-left: 0;
        margin-top: 29px;
        padding: 8px 15px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        i {
          position: relative;
          top: -1px;
        }
      }
      &:not(:first-child) {
        .alt-field-label {
          display: none;
        }
        .alt-form-field {
          padding-top: 0;
        }
        .alt-button {
          margin-top: 0;
        }
      }
    }
    .list-add-button {
      margin-left: 0;
    }
    .alt-text-input {
      display: flex;
      position: relative;
      align-items: center;

      .alt-select {
        flex: 1 1 auto;
      }
    }

    .package-window {
      padding-left: 30px;
      font: 600 12px/1 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
        sans-serif;

      .alt-table-wrapper {
        position: relative;
        padding-top: 10px;
        padding-bottom: 20px;
        width: 400px;

        .alt-table .rt-table .rt-tbody {
          overflow-y: hidden;
        }
      }
    }
  }
}
