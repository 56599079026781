.package-details {
  .package-detail {
    padding: 5px 25px;
    font: 400 14px/1.2 sans-serif;
    border-bottom: 1px solid lightgray;

    &:last-child {
      border-bottom: none;
    }
  }
  .package-error {
    margin: 10px 25px;
    font: 500 14px/1.2 monospace;
    color: #e74c3c;
  }
  .package-version-dp {
    height: 35px;
    display: flex;
    align-items: center;
  }
  .package-version-dp label {
    font: 400 14px/1.2 sans-serif;
    font-weight: bold;
  }
}
