.form-fields {
  width: 840px;
  max-width: 100%;
  margin: 0 auto;
}
.field-group {
  width: 100%;
  margin-bottom: 20px;
}
.field-label {
  margin-left: 15px;
  font: 500 12px/12px 'Helvetica', sans-serif;
  color: #6d6d6d;

  .required {
    font-size: 14px;
    color: red;
  }
}
.field-error {
  margin-top: 4px;
  padding-left: 17px;
  font: 500 12px/12px 'Helvetica', sans-serif;
  color: #fe0001;
}
.field-text {
  display: block;
  position: relative;
  width: 100%;
  padding: 0 0 0 17px;
  border-radius: 17px;
  border: none;
  font: 500 16px/36px 'Helvetica', sans-serif;
  color: #6d6d6d;

  &:focus {
    box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
  }
  &::placeholder {
    opacity: 0.4; /* Firefox */
  }
  &:disabled {
    background: #f2f2f2;
    cursor: not-allowed;
  }
  &.field-has-error {
    border: 1px solid #fe0001;
    background: #fceeed;
  }
}
.field-select {
  width: 100%;
  font: 500 14px/1.4 Helvetica, sans-serif;

  .field-select__control {
    border: none;
    border-radius: 17px;
    transition: none;

    .field-select__value-container {
      padding-left: 17px;

      .field-select__single-value,
      .field-select__placeholder {
        margin-left: 3px;
        color: #c7c7c7;
      }

      &.field-select__value-container--has-value {
        .field-select__single-value,
        .field-select__placeholder {
          color: #6d6d6d;
        }
      }
    }
    .field-select__indicators {
      .field-select__indicator-separator {
        display: none;
      }
    }
    &.field-select__control--is-focused {
      border: none;
      box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1),
        0 4px 11px hsla(0, 0%, 0%, 0.1);
    }
    &.field-select__control--menu-is-open {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  &.field-has-error {
    .field-select__control {
      border: 1px solid #fe0001;
      background: #fceeed;
    }
  }
  .field-select__menu {
    margin-top: 0;
    box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
    border-radius: 0 0 17px 17px;
    overflow: hidden;

    .field-select__menu-list {
      padding: 0;

      .field-select__option {
        &.field-select__option--is-focused {
          background-color: #f4f4f4;
        }
        &.field-select__option--is-selected {
          background-color: #dfdfdf;
          color: black;
        }
      }

      .field-select__menu-notice--no-options {
        padding-left: 17px;
        text-align: left;
      }
    }
  }
}
