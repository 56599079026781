.alt-button-link.alt-button-link {
  flex: 0 0;
  position: relative;
  display: block;
  margin-left: 10px;
  padding: 8px 20px;
  white-space: nowrap;
  border-radius: 5px;
  border: 1px solid lightgray;
  font: 600 16px/1 'TradeGothic', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  background: white;
  color: black;
  cursor: pointer;

  &:hover {
    background: #f5f5f5;
  }
  &.alt-button-link-primary {
    color: #fff;
    background-color: #fc6100;
    border-color: #fc6100;

    &:hover {
      background: #fa5400;
    }
  }
  &.alt-button-link-borderless {
    border-color: transparent;
  }
  &.alt-button-link-disabled {
    opacity: 0.75;
    cursor: not-allowed;

    &:hover {
      background: white;
    }
    &.alt-button-link-primary {
      color: white;

      &:hover {
        background: lightgray;
      }
    }
  }
}
