.dash-main.dash-error {
  margin: 40px;

  .dash-error-title {
    padding-bottom: 10px;
    font: 600 28px/1 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
      sans-serif;
  }
  .dash-error-text {
    font: 500 14px/1.2 monospace;
    color: #e74c3c;
  }
  .dash-error-children {
    padding-top: 10px;

    a {
      font: 500 14px/1 monospace;
      text-decoration: underline;
    }
  }
}
