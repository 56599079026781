.alt-button.release-notes-button {
  position: relative;
  padding: 6px 12px;
  border: none;
  background: transparent;
  color: gray;
  border: 1px dashed lightgray;
  outline: none;

  .fa {
    position: relative;
    padding-left: 3px;
    font-size: 16px;
    color: #434343;
  }

  &.release-notes-button-active {
    color: black;

    .fa:after {
      content: '';
      position: absolute;
      top: -2px;
      right: -4px;
      width: 5px;
      height: 5px;
      border-radius: 25px;
      border: 2px solid #ececec;
      background: #e74c3c;
    }
  }

  .bounce {
    animation: bounce 2s ease infinite;
  }

  @keyframes bounce {
    70% {
      transform: translateY(0%);
    }

    80% {
      transform: translateY(-40%);
    }

    90% {
      transform: translateY(0%);
    }

    95% {
      transform: translateY(-7%);
    }

    97% {
      transform: translateY(0%);
    }

    99% {
      transform: translateY(-3%);
    }

    100% {
      transform: translateY(0);
    }
  }

  &:hover {
    color: black;
    border: 1px solid lightgray;

    &.release-notes-button-active {
      .fa:after {
        border-color: #f5f5f5;
      }
    }
  }
}