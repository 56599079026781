.alt-page-wrapper {
  .dash-main.packages {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .list-header {
      flex: 0 0;
      position: relative;
      padding: 0 20px;
      padding-top: 15px;
      padding-bottom: 8px;
      border-bottom: 1px solid #d6d6d6;
      background: white;

      .list-title {
        padding: 10px 0;
        font: 600 20px/1 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
          sans-serif;
      }

      .list-header-bar {
        display: flex;
        flex-direction: row;
      }
    }
    .dash-main-list {
      flex: 1 1;
      position: relative;
      width: 100%;
      background: white;
    }
    .list-split {
      flex: 0 0;
      position: relative;
      padding: 8px 20px;
      padding-bottom: 8px;
      border-bottom: 1px solid #d6d6d6;
      border-top: 1px solid #d6d6d6;
      background: white;

      .list-split-bar {
        display: flex;
        flex-direction: row;

        .list-title {
          position: relative;
          flex: 0 0;
          padding: 0;
          padding-top: 3px;
          white-space: nowrap;
          font: 600 16px/1 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
            sans-serif;

          .list-subtitle {
            display: block;
            padding-top: 2px;
            font: 600 12px/1 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
              sans-serif;
            color: gray;
          }
        }
        .alt-form-field {
          flex: 0 0 180px;
          padding: 0;
          padding-left: 20px;
          padding-right: 10px;

          .alt-field-label {
            display: none;
          }
        }
        .list-action {
          flex: 0 0;
          display: block;
          margin-left: 10px;
          padding: 8px 20px;
          white-space: nowrap;
          border-radius: 5px;
          border: 1px solid lightgray;
          font: 600 16px/1 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
            sans-serif;
          background: white;
          cursor: pointer;

          &:hover {
            background: #f5f5f5;
          }
          &.disabled {
            opacity: 0.75;
            cursor: not-allowed;

            &:hover {
              background: white;
            }
          }
        }
      }
    }
  }
}
