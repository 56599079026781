.alt-page-wrapper {
  .dash-main.file-edit {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: white;

    .file-edit-header {
      flex: 0 0;
      width: 100%;
      display: flex;
      flex-direction: row;
      padding: 5px 20px;
      padding-top: 45px;
      border-bottom: 1px solid lightgray;

      .file-edit-header-title {
        position: relative;
        flex: 1 1;
        top: 10px;
        font: 600 20px/1 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
          sans-serif;
      }
    }
    .file-name-row {
      position: relative;
      display: flex;
      flex-direction: row;

      padding: 5px;
      border-bottom: 1px solid lightgray;
      background: #f7f7f7;

      .base-folder {
        padding: 5px 10px;
        font: 14px/24px monospace;
      }
      .file-name {
        position: relative;
        flex: 1 1;

        input {
          width: 100%;
          padding: 8px;
          padding-left: 12px;
          border: 1px solid lightgray;
          font: 400 14px/1 sans-serif;
          border-radius: 3px;
        }
        .file-name-error {
          padding-top: 5px;
          font: 400 12px/1 sans-serif;
          color: #c11a1a;
        }
        .file-name-loading {
          position: absolute;
          top: 10px;
          right: 10px;
          height: 14px;
          width: 14px;
          border-radius: 50%;
          border: 1px solid lightgray;
          border-top: 1px solid black;
          animation: alt-spinner 0.6s linear infinite;

          @keyframes alt-spinner {
            to {
              transform: rotate(360deg);
            }
          }
        }
      }
    }
    .file-error {
      padding: 8px;
      border-bottom: 1px solid lightgray;
      font: 400 12px/1 sans-serif;
      background: rgba(255, 69, 50, 0.25);

      .fa {
        padding-right: 5px;
        color: #c11a1a;
      }
    }
    .edit-container {
      max-width: calc(100vw - 240px);
      flex: 1 1;
    }
    .edit-container-release-notes-open {
      max-width: calc(100vw - 650px);
    }
  }
}
