.alt-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.714902);
  z-index: 2000;

  display: flex;
  justify-content: center;
  align-items: center;
}
.alt-modal {
  display: block;
  position: relative;
  overflow: auto;

  width: calc(100% - 40px);
  max-width: 580px;

  height: auto;
  max-height: calc(100% - 40px);

  margin: 20px auto !important;
  border-radius: 5px;
  background-color: white;
  outline: none;

  .alt-modal-exit {
    position: absolute;
    top: 10px;
    right: 10px;
    color: lightgray;
    font-size: 15px;
    cursor: pointer;
    z-index: 900;
  }
  .alt-modal-content {
    position: relative;
    height: 100%;
    overflow: hidden;
  }
}
