.alt-page-wrapper {
  .dash-main.events {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    height: 100%;

    .events-header {
      display: flex;
      flex-direction: row;
      flex: 0 0;
      position: relative;
      padding: 10px 20px;
      border-bottom: 1px solid lightgray;
      background: white;

      .events-header-title {
        position: relative;
        flex: 1 1;
        padding: 0;
        padding-top: 3px;
        white-space: nowrap;
        font: 600 16px/1 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
          sans-serif;

        .events-header-subtitle {
          display: block;
          padding-top: 2px;
          font: 600 12px/1 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
            sans-serif;
          color: gray;
        }
      }
      .events-filter {
        flex: 0 1 125px;
        margin-left: 10px;
        padding: 0;

        .alt-field-label {
          display: none;
        }
        .alt-text-input > input {
          padding: 7px 12px;
        }
        .alt-select {
          min-width: 80px;
        }
        ::placeholder {
          font: 600 16px/1 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
            sans-serif;
        }
        &.alt-date-range {
          flex: 0 0 278px;
        }
      }
      .alt-search-button {
        height: 32px;
        padding: 8px 16px;
        font-size: 14px;

        i.fa {
          position: relative;
          font-size: 14px;
        }
      }
    }
    .dash-main-list {
      flex: 1 1;
      position: relative;
      width: 100%;
      background: white;
    }
  }
}
