.alt-page-wrapper {
  .dash-main.settings {
    position: relative;
    flex: 1 1;
    height: 100%;
    background: white;

    .settings-inner {
      // dont scroll the whole page, only the content
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding-bottom: 20px;
    }
    .settings-title {
      padding: 40px;
      padding-bottom: 20px;
      font: 600 28px/1 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
        sans-serif;
    }
    .settings-description {
      max-width: 960px;
      padding: 0 40px;
      font: 500 16px/1.2 sans-serif;
    }
    .settings-error {
      margin: 20px 40px;
      font: 500 14px/1.2 monospace;
      color: #e74c3c;
    }
    .settings-form {
      flex: 1 1;
      height: 100%;
      width: 100%;
      max-width: 100%;
      padding: 0;
      padding-top: 40px;
      background: white;

      .settings-form-section {
        padding: 0 40px;
        padding-top: 10px;
        padding-bottom: 30px;
        border-top: 1px solid lightgray;

        &.section-notifications {
          .split-group {
            > :nth-child(1) {
              flex: 1 1;

              .alt-text-input {
                input {
                  border-top-right-radius: 0;
                  border-bottom-right-radius: 0;
                  padding: 7px 12px;
                }
              }
            }
            > :nth-child(2) {
              flex: 0 0 100px;

              .alt-select {
                min-width: 110px;

                .select__control {
                  border-radius: 0;
                  border-left: none;
                  border-right: none;
                }
              }
            }
          }
        }
        .settings-form-section-title {
          position: relative;
          display: inline-block;
          top: -20px;
          font: 600 20px/1 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
            sans-serif;
          text-transform: uppercase;
          background: white;
        }
        .settings-form-section-description {
          position: relative;
          top: -5px;
          max-width: 660px;
          font: 400 14px/1 'Helvetica Neue Regular', Helvetica, Arial;

          a {
            text-decoration: underline;
          }
        }
        .settings-error {
          margin: 10px 0;
        }
        .settings-form-section-inner {
          max-width: 660px;

          .split-group {
            display: flex;
            flex-direction: row;

            .alt-form-field {
              padding-bottom: 5px;

              .alt-field-error {
                position: relative;
              }
            }
            .alt-button {
              height: 32px;
              margin-left: 0;
              margin-top: 29px;
              padding: 8px 15px;
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;

              i {
                position: relative;
                top: -1px;
              }
            }
            &:not(:first-child) {
              .alt-field-label {
                display: none;
              }
              .alt-form-field {
                padding-top: 0;
              }
              .alt-button {
                margin-top: 0;
              }
            }
          }
          .button-row {
            display: flex;
            flex-direction: row;

            .alt-button:first-child {
              margin-left: 0;
            }
            &.countdown-row {
              .alt-button {
                margin-left: 0;

                p {
                  display: inline;
                }
                &:first-child {
                  border-top-right-radius: 0;
                  border-bottom-right-radius: 0;
                  border-right: none;
                }
                &:last-child {
                  border-top-left-radius: 0;
                  border-bottom-left-radius: 0;
                }
              }
            }
          }
          .countdown {
            mark {
              margin: 0 2px;
              padding: 2px 5px;
              border-radius: 5px;
              background: lightgray;
            }
          }
        }
        .settings-form-section-buttons {
          float: right;

          .cancel-button {
            display: inline;
            margin-top: 20px;
            margin-bottom: 30px;
            border: 0;
          }

          .submit-button {
            display: inline;
            margin-top: 20px;
            margin-bottom: 30px;
            color: #fff;
            background-color: #fc6100;
            border: 0;
          }
        }
      }
    }
    .alt-form-field {
      // z-index: 450;
    }
    .tagguid-field {
      .tagguid-option {
        .tagguid-option-label {
          font: 600 12px/1 'Trade Gothic', 'Helvetica Neue Regular', Helvetica,
            Arial, sans-serif;
        }
        .tagguid-option-value {
          font: 400 10px/1 sans-serif;
        }
      }
      // react-select override
      .alt-select {
        .select__value-container--menu-is-open {
          z-index: 2000;
        }

        .select__menu {
          .select__menu-list {
            .select__option {
              padding: 4px 12px;
            }
          }
        }
      }
    }
    .toggle-field {
      .alt-toggle-input {
        display: flex;
      }
      .alt-toggle {
        align-self: center;
      }
    }
  }
}
