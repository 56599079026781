.alt-region-select {
  min-width: 180px;
  height: 32px;
  font: 600 14px/1 'Trade Gothic', 'Helvetica Neue Regular', Helvetica, Arial,
    sans-serif;

  .region-select__control {
    min-height: 32px;
    height: 32px;
    border: 1px solid lightgray;
    border-radius: 5px;
    transition: none;

    .region-select__value-container {
      padding-left: 10px;

      .region-select__single-value,
      .region-select__placeholder {
        margin-left: 3px;
        color: #c7c7c7;
      }

      &.region-select__value-container--has-value {
        .region-select__single-value,
        .region-select__placeholder {
          color: #404040;
        }
      }
      .region-select__input {
        height: 16px;
        padding: 0;
      }
    }
    .region-select__indicators {
      padding: 0;

      .region-select__indicator {
        padding: 4px;
      }
      .region-select__indicator-separator {
        display: none;
      }
    }
    &.region-select__control--is-focused {
      border: 1px solid gray;
      box-shadow: none;
    }
    &.region-select__control--menu-is-open {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  .region-select__menu {
    margin-top: 0;
    border: 1px solid gray;
    border-top: none;
    border-radius: 0 0 5px 5px;
    overflow: hidden;

    .region-select__menu-list {
      padding: 0;

      .region-select__option {
        &.region-select__option--is-focused {
          background-color: #f4f4f4;
        }
        &.region-select__option--is-selected {
          background-color: #dfdfdf;
          color: black;
        }
      }

      .region-select__menu-notice--no-options {
        padding-left: 10px;
        text-align: left;
      }
    }
  }
}
