.dash-main.provisioning {
  position: relative;
  flex: 1 1;
  display: flex;
  padding: 40px;
  flex-direction: column;
  height: 100%;

  .provisioning-title {
    padding-bottom: 10px;
    font: 600 22px/1 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
      sans-serif;
  }
  .provisioning-subtitle {
    max-width: 680px;
    padding-bottom: 20px;
    font: 500 16px/1.2 monospace;

    a {
      text-decoration: underline;
    }
  }

  .steps {
    position: relative;

    .step {
      position: relative;
      margin-bottom: 3px;
      padding: 5px;
      padding-left: 30px;
      border-left: 2px solid lightgray;
      background: #efefef;
      opacity: 0.5;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 11px;
        height: calc(100% + 3px);
        width: 2px;
        border: 2px solid white;
        border-width: 0 2px;
        background: lightgray;
      }
      &:first-child:before {
        top: 5px;
        height: calc(100% - 2px);
      }
      &:last-child:before {
        height: 10px;
      }
      .step-inner {
        position: relative;

        .step-title {
          text-transform: uppercase;
          font: 600 16px/20px 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
            sans-serif;
        }
        .step-spinner {
          position: absolute;
          top: 0px;
          left: -26px;
          width: 20px;
          height: 20px;
          padding: 2px;
          border-radius: 5px;
          background: #cbcbcb;

          .fa-check {
            display: none;
            position: relative;
            top: -2px;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
          }
          .step-spinning {
            display: none;
            position: relative;
            top: 2px;
            left: 2px;
            border-radius: 50%;
            border: 1px solid white;
            border-top: 1px solid black;
            animation: alt-spinner 0.8s linear infinite;
            height: 12px;
            width: 12px;

            @keyframes alt-spinner {
              to {
                transform: rotate(360deg);
              }
            }
          }
        }
        .sub-steps {
          padding: 5px 0;

          .sub-step {
            position: relative;
            padding-bottom: 5px;

            &::before {
              content: '';
              position: absolute;
              top: 4px;
              left: -21px;
              height: 6px;
              width: 6px;
              border: 2px solid white;
              border-radius: 5px;
              background: lightgray;
            }

            .sub-step-title {
              font: 500 14px/1.2 monospace;
            }
          }
        }
      }
    }
    .step.green {
      opacity: 1;

      &:before {
        background: #8cc14f;
      }

      .step-inner {
        .step-spinner {
          background: #8cc14f;
          color: white;

          .fa-check {
            display: inline-block;
          }
        }
        .sub-steps {
          .sub-step {
            &::before {
              background: #8cc14f;
            }
          }
        }
      }
    }
    .step.active {
      opacity: 1;

      .step-inner {
        .step-spinner {
          .step-spinning {
            display: block;
          }
        }
      }
    }
  }
}
