.config-section {
  .config-section-title {
    position: relative;
    display: inline-block;
    margin-top: 20px;
    margin-left: 35px;
    padding: 0 5px;
    font: 600 20px/1 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
      sans-serif;
    background: white;
    z-index: 400;
  }
  .config-section-title-editable {
    position: relative;
    display: block;
    margin-top: 20px;
    margin-left: 35px;
    z-index: 400;

    input {
      position: relative;
      display: block;
      width: 100%;
      padding: 0 5px;
      border: 0;
      font: 600 20px/1 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
        sans-serif;

      &::placeholder {
        color: lightgray;
      }
    }
  }
  .config-section-divider {
    position: relative;
    width: 100%;
    height: 1px;
    margin: 0;
    border: 0;
    top: -10px;
    background: lightgray;
    z-index: 100;
  }

  .config-field {
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 10px;
    padding-right: 40px;
    max-width: 920px;

    .config-field-label {
      flex: 0 0 300px;
      text-align: right;
      padding: 8px;
      padding-top: 10px;
      padding-right: 20px;
      font: 400 16px/1 sans-serif;
    }
    .config-field-label-editable {
      position: relative;
      flex: 0 0 290px;
      text-align: right;
      padding: 2px;
      margin-right: 10px;

      input {
        width: 100%;
        padding: 6px 10px;
        border-radius: 2px;
        border: none;
        font: 400 16px/1 sans-serif;
        text-align: right;
        background: white;
      }
      .config-field-key-error {
        position: absolute;
        bottom: -4px;
        right: 10px;
        font: 400 12px/1 sans-serif;
        color: red;
      }
    }
    .config-field-input {
      position: relative;
      flex: 1 1;
      padding: 2px;
      border-radius: 3px;
      border: 1px solid lightgray;
      background: white;

      input {
        width: 100%;
        padding: 6px 10px;
        border-radius: 2px;
        border: none;
        font: 400 14px/1 sans-serif;
        background: white;
      }
      &.changed {
        background: #d7e4ff;
      }
      &:nth-last-child(2) {
        border-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    .config-field-remove,
    .config-field-revert {
      margin-left: 0;
      line-height: 14px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  .add-config-section-row {
    display: flex;
    flex-direction: row-reverse;
    padding-right: 40px;
    max-width: 920px;
  }
}
