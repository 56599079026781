#notification-pager-duty {
  width: 100%;

  .notification-config-row {
    display: flex;
    max-width: calc(660px + 80px);

    > :not(:first-child) {
      margin-left: 5px;
    }
  }

  .notification-config-pd-service-key,
  .notification-config-pd-client-name {
    display: flex;
    flex: 1 1 auto;
    padding-bottom: 5px;

    .alt-text-input > input {
      height: 32px;
    }
    .alt-field-error {
      bottom: -8px;
      white-space: nowrap;
    }
  }

  .notification-config-pd-service-key,
  .notification-config-pd-client-name {
    flex: 1 1 200px;
  }
}
