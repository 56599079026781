.alt-decommission-cluster {
  .dialogue-header {
    padding-bottom: 5px;
  }
  .dialogue-body {
    border: none;
  }
  .input-confirm {
    display: block;
    position: relative;
    width: 300px;
    max-width: 100%;
    margin: 20px 25px;
    margin-bottom: 5px;
    padding: 8px 10px;
    border-radius: 5px;
    border: 1px solid gray;
    font: 400 14px/1 sans-serif;
    text-align: left;

    &.error {
      border-color: red;
    }
    .input-label {
      position: absolute;
      top: -16px;
      left: 10px;
      font: 500 12px/1 'Helvetica Neue', Helvetica, Arial, sans-serif;
      color: gray;
    }
    .input-placeholder {
      position: absolute;
      top: 9px;
      left: 10px;
      font: 500 14px/1 'Helvetica Neue', Helvetica, Arial, sans-serif;
      z-index: 100;
      color: lightgray;
    }
    input {
      position: relative;
      width: 100%;
      height: 14px;
      border: none;
      padding: 0;
      font: 500 14px/1 'Helvetica Neue', Helvetica, Arial, sans-serif;
      z-index: 300;
      background: transparent;
      color: black;
    }
  }
}
