.alt-page-wrapper {
  .dash-sidebar {
    position: relative;
    height: 100%;
    flex: 0 0 var(--sidebar-width);
    border-right: 1px solid #d6d6d6;
    background: white;
    word-break: break-word;
    .react-tooltip {
      z-index: 10000;
    }
    .sidebar-title {
      padding-top: 40px;
      padding-left: 30px;
      padding-bottom: 5px;

      .back {
        font: 600 14px/1 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
          sans-serif;
        color: gray;
      }

      .cluster-name-container {
        display: grid;
        grid-template-columns: auto 22%;
        align-items: center;

        .copy-to-clipboard-button {
          margin-left: 1px;
          margin-right: 5px;
          padding: 2px 5px;
        }
        i {
          font-size: 12px;
          margin-left: 1px;
          margin-right: 1px;
          padding: 1px;
        }
      }

      .cluster-name {
        scroll-padding-right: 20px;
        font: 600 22px/1 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
          sans-serif;
      }
    }

    .col-line-detail {
      margin: 0;
    }

    .sidebar-link {
      display: block;
      position: relative;
      padding: 10px 20px;
      padding-left: 25px;
      border-bottom: 1px solid #ececec;
      font: 600 14px/1 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
        sans-serif;
      color: #3c3c3c;
      background: white;
      cursor: pointer;

      &:first-of-type {
        border-top: 1px solid #ececec;
      }

      .fa {
        padding-right: 10px;
        color: lightgray;
      }

      .beta-flag {
        position: absolute;
        top: 10px;
        right: 20px;
        color: lightgray;
      }

      &.active,
      &:not(.link-disabled):hover {
        transition: all 0.1s ease;
        border-left: 15px solid #ececec;
      }

      &.sidebar-sublink {
        border-left: 30px solid #ececec;
      }

      .sidebar-link-expander {
        padding-left: 50%;
      }

      &.link-disabled {
        opacity: 0.25;
        cursor: not-allowed;
      }

      &.sidebar-link-external {
        padding-top: 15px;
        padding-bottom: 0;
        border: none;

        &:not(.link-disabled):hover {
          text-decoration: underline;
          border-left-width: 0;
        }

        &:before {
          content: '→';
          position: absolute;
          top: 12px;
          right: 15px;
          height: 18px;
          font-size: 18px;
          line-height: 1;
        }

        &.link-disabled {
          opacity: 0.25;
          cursor: not-allowed;
        }
      }
    }

    .btn-restart,
    .btn-action {
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 5px 12px;
      font-size: 14px;
      border-radius: 15px;
      z-index: 2; // Action dropdown should be above copy button

      .fa-refresh {
        position: relative;
        left: 5px;
      }

      .alt-button-lock-icon {
        line-height: 24px;
        font-size: 12px;
      }
    }

    .btn-help {
      margin: 20px auto;
    }
  }
}
