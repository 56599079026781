@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
@import './fonts.scss';
@import './form.scss';

html,
body,
#app-host {
  width: 100vw;
  height: auto;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  background-color: #ffffff !important;
}
h1,
h2,
h3,
h4,
h5,
p {
  margin-block-start: 0;
  margin-block-end: 0;
}

$breakpoint-hamburger: 1024px;
@media (max-width: $breakpoint-hamburger) {
  #app-host.af1-app {
    .integratedNavContainer {
      #content-host {
        .navbar-wrapper {
          .navbar {
            width: 350px;
            > div {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

#app-host.af1-app {
  .integratedNavContainer {
    min-height: 100vh;

    #content-host {
      position: relative;
      width: 100vw;
      height: 100%;
      display: flex;
      flex-direction: column;

      > :first-child {
        //z-index: 1800;
        z-index: 1;
      }

      .navbar-wrapper {
        //z-index: 1600;
        z-index: 1;

        .navbar {
          > a:first-child > img {
            transition: transform 0.8s ease-in-out;

            &:hover {
              transform: rotate(360deg);
            }
          }
        }
      }
    }
  }
}
.nav-aside {
  display: flex;
  flex-direction: row;

  .alt-news-open {
    line-height: 32px;
    cursor: pointer;
  }
  .alt-region-select {
    margin-left: 10px;
  }
}
.alt-full-view-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  flex: 1 1;
  border-top: 1px solid #d6d6d6;

  .alt-full-cols {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;

    .alt-view-wrapper {
      flex: 1 1;
      max-width: 100vw;
    }
  }
}
.alt-scroll {
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 16px;
    background: #e4e4e4;

    &:horizontal {
      display: none;
    }
  }
  &::-webkit-scrollbar-track:vertical {
    width: 16px;
  }
  &::-webkit-scrollbar-thumb {
    width: 8px;
    border-radius: 16px;
    background-color: #ffffff;
    border: 4px solid #e4e4e4;
  }
}
.disabled-feature {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 400;
  background: rgba(0, 0, 0, 0.05);

  .disabled-feature-text {
    position: absolute;
    top: calc(15% + 40px);
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px 30px;
    border-radius: 5px;
    border: 1px solid lightgray;
    text-transform: uppercase;
    font: 600 14px/24px 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
      sans-serif;
    background: white;
    color: gray;
  }
}
// target attribute with id instead of id itself resulting in a low specificity
// allows us to write 'global' styles that dont overpower lightly scoped ones
[id='content-host'],
[class='ReactModalPortal'] {
  -webkit-font-smoothing: antialiased;

  * {
    box-sizing: border-box;
  }
  *:focus {
    outline: none;
  }
  a {
    text-decoration: none;
    color: black;
  }
  i {
    font-style: normal;
  }
  button {
    border: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    -webkit-appearance: none;
  }
  table {
    border-collapse: separate;
    border-spacing: 0;
  }
}
