.alt-form {
  position: relative;
  display: block;
  width: 100%;
  max-width: 740px;
  padding: 20px;
  margin: 0 auto;

  .alt-form-header {
    padding-bottom: 10px;

    .alt-form-title {
      padding-bottom: 10px;
      font: 600 20px/1 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
        sans-serif;
    }
    .alt-form-subtitle {
      padding-bottom: 10px;
      font: 500 16px/1.4 sans-serif;
    }
  }
  .alt-form-footer {
    display: flex;
    flex-direction: row;
    padding-top: 5px;

    > :first-child {
      margin-left: 0;
    }
  }
}
