.alt-health {
  .alt-health-item {
    position: relative;
    padding-top: 8px;
    padding-left: 30px;
    margin-bottom: 5px;

    &:last-of-type {
      margin-bottom: 20px;
    }

    .alt-health-item-title {
      font: 600 12px/1 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
        sans-serif;
      color: gray;
    }

    .alt-health-item-status {
      position: relative;
      padding-left: 15px;
      font: 500 13px/16px 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
        sans-serif;

      &:after {
        content: '';
        position: absolute;
        top: 4px;
        left: 2px;
        width: 8px;
        height: 8px;
        border-radius: 25px;
        background: lightgray;
      }

      &.alt-health-green:after {
        background: #2ecc71;
      }

      &.alt-health-yellow:after {
        background: yellow;
      }
    }

    .btn-restart.scheduler {
      margin-left: 10px;
      padding: 5px 10px;

      .fa-refresh {
        left: 0;
      }
    }
  }
}
