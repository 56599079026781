@font-face {
  font-family: 'FuturaExtraBold';
  font-weight: normal;
  src: url('../fonts/futura/futura-condensed-extra-bold.woff');
}

@font-face {
  font-family: 'Helvetica';
  font-weight: normal;
  src: url('../fonts/helvetica/helvetica.woff') format('woff');
}

@font-face {
  font-family: 'TradeGothic';
  font-weight: normal;
  src: url('../fonts/trade-gothic/tradegothic-nike365-bold-condensed.woff')
    format('woff');
}
