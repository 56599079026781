.alt-areatext-input {
  position: relative;

  textarea {
    width: 100%;
    height: 100px;
    padding: 8px 12px;
    border-radius: 5px;
    border: 1px solid lightgray;
    font: 400 14px/16px sans-serif;

    &:disabled {
      background: #f3f3f3;
      cursor: not-allowed;
    }
  }

  .alt-field-error {
    background-color: yellow;
  }
}
