.offline-banner {
  width: 100%;
  flex: 0 0;
  display: block;
  padding: 12px;
  text-align: center;
  font: 400 14px/1 sans-serif;
  background: #fc6201;
  color: white;

  .fa {
    padding-right: 6px;
  }
  .offline-banner-bold {
    padding-right: 6px;
    font-weight: 600;
  }
}
