.alt-dropzone {
  position: relative;
  display: block;

  .alt-drop-preview {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 80px;
    background: white;

    .alt-drop-preview-inner {
      position: relative;
      width: 360px;
      margin: 0 auto;
      padding: 60px 20px;
      border-radius: 15px;
      border: 2px dashed lightgray;
      background: white;
      z-index: 400;

      .alt-drop-preview-icon,
      .alt-drop-preview-icon-error {
        margin-bottom: 12px;
        text-align: center;
        font-size: 24px;
        line-height: 1;
        color: gray;
      }
      .alt-drop-preview-icon-error {
        color: #e74c3c;
        animation: alt-drop-preview-icon-shake 0.5s
          cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        perspective: 1000px;

        // cloud
        font-size: 18px;
        width: 35px;
        height: 12px;
        margin: 0 auto;
        margin-bottom: 10px;
        background: lightgray;
        border-radius: 100px;
        position: relative;

        &:after,
        &:before {
          content: '';
          position: absolute;
          background: lightgray;
          z-index: -1;
        }
        &:after {
          width: 10px;
          height: 10px;
          top: -5px;
          left: 5px;
          border-radius: 100px;
        }
        &:before {
          width: 18px;
          height: 18px;
          top: -9px;
          right: 5px;
          border-radius: 20px;
        }

        @keyframes alt-drop-preview-icon-shake {
          10%,
          90% {
            transform: translate3d(-1px, 0, 0);
          }
          20%,
          80% {
            transform: translate3d(2px, 0, 0);
          }
          30%,
          50%,
          70% {
            transform: translate3d(-4px, 0, 0);
          }
          40%,
          60% {
            transform: translate3d(4px, 0, 0);
          }
        }
      }
      .alt-drop-preview-text {
        text-align: center;
        font: 400 18px/1 sans-serif;
      }
    }
    .alt-button {
      top: 20px;
      margin: 0 auto;
    }
    .alt-drop-hover-ripple {
      position: absolute;
      width: 8000px;
      height: 8000px;
      transform: translateX(-50%) translateY(-50%);
      border-radius: 1000px;
      background: lightgray;
      opacity: 0.15;
      animation: 0.25s ease-in 0s 1 alt-drop-hover-ripple;
      z-index: 200;
    }
    @keyframes alt-drop-hover-ripple {
      0% {
        width: 20px;
        height: 20px;
      }
      100% {
        width: 8000px;
        height: 8000px;
      }
    }
  }
}
