#notification-include-dags {
  .alt-form-field.placeholder-title {
    padding-top: 0px;
    padding-bottom: 5px;
  }
  .notification-config-include-dags {
    display: flex;
    flex-direction: row;
    padding-bottom: 5px;
    max-width: calc(660px + 80px);
    &:not(:first-child) {
      .alt-field-label {
        display: none;
      }
      .alt-members-delete-btn.alt-button {
        margin-top: 0;
      }
    }
    .alt-form-field {
      padding: 0;
    }
    .notification-input-dags {
      flex: 0 0 443px;
      .alt-creatable-select-input {
        .creatable-select__control {
          border-right: none;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
    .notification-input-severity {
      flex: 0 0 90px;
      .alt-select {
        min-width: 90px;
        .select__control {
          border-radius: 0;
        }
      }
    }
    .alt-members-delete-btn.alt-button {
      height: 32px;
      margin-left: 0;
      margin-top: 19px;
      padding: 8px 15px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border: 1px solid lightgray;
      border-left: none;
    }
    .creatable-select__control {
      height: inherit;
    }
    .alt-field-error {
      bottom: -8px;
      white-space: nowrap;
    }
  }
  .alt-button.notification-include-dags-add-row-btn {
    margin-left: 0;
  }
}
