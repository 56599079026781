.alt-kv-tags {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-radius: 3px;
  border: 1px solid lightgray;
  padding: 5px;
  padding-bottom: 0;

  .alt-kv-tag {
    position: relative;
    flex: 0 0;
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    border-radius: 3px;
    border: 1px solid lightgray;

    &:not(:last-child) {
      margin-right: 5px;
    }

    .alt-kv-tag-key {
      position: relative;
      flex: 0 0 180px;
      background: lightgray;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: -6px;
        border-top: 24px solid lightgray;
        border-right: 6px solid transparent;
      }
      input {
        padding-left: 6px;
      }
    }
    .alt-kv-tag-value {
      position: relative;
      flex: 1 1;

      input {
        padding-left: 16px;
      }
    }
    .alt-kv-remove {
      position: relative;
      flex: 0 0 20px;
      padding: 3px 0;
      padding-left: 6px;
      background: lightgray;

      i {
        font-size: 16px;
        line-height: 1;
        cursor: pointer;
        color: gray;
      }
    }
  }

  input {
    width: 100%;
    padding: 4px 8px;
    border: none;
    font: 400 14px/14px monospace;
    background: transparent;
  }

  .alt-kv-add {
    position: relative;
    flex: 0 0;
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 3px;
    font-size: 16px;
    line-height: 1;
    color: gray;

    i {
      cursor: pointer;
    }
  }

  &.alt-kv-tags-disabled {
    cursor: not-allowed;
    background: #f3f3f3;

    .alt-kv-add {
      i {
        cursor: not-allowed;
      }
    }
    .alt-kv-tag {
      .alt-kv-remove {
        i {
          cursor: not-allowed;
        }
      }
    }
  }
}
