.spinner {
  position: relative;
  margin: 0 auto;
  border-radius: 50%;
  border-top: 2px solid #03ade0;
  border-right: 2px solid transparent;
  animation: spinner 0.6s linear infinite;
  height: 20px;
  width: 20px;

  &.spinner-md {
    height: 95px;
    width: 95px;
  }
  &.spinner-lg {
    height: 138px;
    width: 138px;
  }
  &.spinner-center {
    top: calc(20vh - 30px);
  }
  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }
}
