#section-notifications {
  padding-left: 0;
  padding-right: 0;

  .settings-form-section-title {
    margin-left: 40px;
  }

  .settings-form-section-inner {
    padding-top: 5px;
    max-width: 100%;

    .notification-description {
      height: fit-content;
      margin: 0px 40px 15px 40px;
      max-width: 660px;

      .alt-form-error {
        padding-top: 10px;
      }
    }
  }

  .button-row {
    padding: 0 40px;
    padding-top: 5px;
    max-width: calc(660px + 80px);
  }

  .notification-config {
    padding: 12px 0;
    display: flex;
    margin: 5px 0;
    padding-left: 40px;

    .alt-field-label {
      width: max-content;
    }

    .notification-config-container {
      display: flex;
      width: 660px;
      margin-right: 40px;
      border-bottom: 1px solid lightgray;

      .notification-config-column {
        flex-wrap: wrap;

        .alt-button.notification-config-delete {
          border: none;
          margin-top: 25px;
        }
      }

      .left-column {
        flex: 1 1 auto;
        margin-right: 20px;
      }

      .notification-extra-input {
        display: flex;
        flex-wrap: wrap;
        max-width: calc(660px + 80px);
        flex-direction: column;
      }
    }
  }
}
