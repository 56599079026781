.alt-page-wrapper {
  .dash-main.builds {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    height: 100%;

    .list-header {
      flex: 0 0;
      position: relative;
      padding: 0 20px;
      padding-top: 15px;
      padding-bottom: 8px;
      border-bottom: 1px solid #d6d6d6;
      background: white;

      .list-title {
        padding: 10px 0;
        font: 600 20px/1 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
          sans-serif;
      }

      .list-header-bar {
        display: flex;
        flex-direction: row;
      }
    }
    .dash-main-list {
      flex: 1 1;
      position: relative;
      width: 100%;
      background: white;

      .alt-build-link {
        text-decoration: underline;
      }
      .alt-builds-list-table {
        .alt-table {
          .rt-table {
            .rt-thead {
              .rt-tr {
                padding-left: 12px;
              }
            }
            .rt-tbody {
              .rt-tr-group {
                .rt-tr {
                  padding-left: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}
