.alt-page-wrapper {
  .dash-main.logs {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    height: 100%;

    .logs-header {
      flex: 0 0;
      position: relative;
      padding: 10px 20px;
      border-bottom: 1px solid #d6d6d6;
      background: white;

      display: flex;
      flex-direction: row;
      text-decoration: capitalize;

      .list-title {
        position: relative;
        flex: 0 1;
        margin-right: auto;
        padding: 0;
        padding-top: 3px;
        white-space: nowrap;
        font: 600 16px/1 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
          sans-serif;

        .list-subtitle {
          display: block;
          padding-top: 2px;
          font: 600 12px/1 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
            sans-serif;
          color: gray;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .logs-service,
      .logs-period,
      .logs-range,
      .logs-dag-search {
        flex: 0 1 110px;
        margin-left: 10px;
        padding: 0;

        .alt-field-label {
          display: none;
        }

        .alt-select {
          min-width: 110px;
        }

        .alt-creatable-select-input {
          width: 300px;
        }
      }

      .logs-range {
        #alt-date-input {
          border-radius: 5px 0 0 5px;
          border-right-width: 0;
        }
      }

      .logs-period {
        flex: 0 1 70px;
        margin-left: 0;

        .alt-select {
          min-width: 70px;

          .select__control {
            border-radius: 0 5px 5px 0;

            &.select__control--menu-is-open {
              border-bottom-right-radius: 0;
            }
          }
        }
      }

      .alt-back-button,
      .alt-forward-button {
        height: 32px;
        padding: 8px 12px;
        font-size: 14px;
        color: #222222;
      }

      .alt-back-button {
        border-radius: 5px 0 0 5px;
      }

      .alt-forward-button {
        border-radius: 0 5px 5px 0;
        border-left-width: 0;
        margin-left: 0;
      }

      .alt-refresh-button {
        flex: 0 0 40px;
        height: 32px;
        padding: 8px 12px;
        font-size: 14px;

        i.fa {
          position: relative;
          font-size: 14px;
        }

        &.alt-button-disabled {
          i.fa {
            animation: spinner 0.8s linear infinite;

            @keyframes spinner {
              to {
                transform: rotate(360deg);
              }
            }
          }
        }
      }
    }

    .logs-main {
      flex: 1 1;
      position: relative;
      width: 100%;
      background: #f6f6f6;

      .logs-error {
        padding: 20px;
        font: 500 14px/1.2 monospace;
        color: #e74c3c;
      }

      .log {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid lightgray;
        font: 500 14px/1.2 monospace;

        .log-timestamp {
          flex: 0 0 192px;
          padding: 4px;
          padding-left: 20px;
          color: #000095;
        }

        .log-message {
          flex: 1 1;
          padding: 4px;
          padding-right: 20px;
          word-break: break-all;
        }

        &.log-skeleton {
          opacity: 0.85;

          .log-timestamp,
          .log-message {
            position: relative;
            height: 10px;
            overflow: hidden;
            border-radius: 25px;
            background: lightgray;
          }

          .log-timestamp {
            flex: 0 0 162px;
            margin: 8px 4px;
            margin-left: 20px;
          }

          .log-message {
            margin: 8px 20px;
          }
        }
      }

      .logs-cap {
        font: 500 14px/1.2 monospace;
        padding: 10px;
        padding-left: 20px;
        padding-bottom: 30px;
      }
    }
  }
}