.alt-toggle {
  position: relative;
  width: 38px;
  height: 22px;
  border-radius: 25px;
  border: 1px solid #cccbcb;
  background: white;
  cursor: pointer;

  .alt-toggle-inner {
    position: absolute;
    top: 1px;
    left: 1px;
    width: 18px;
    height: 18px;
    border-radius: 25px;
    background: #cccbcb;
    transition: left 0.05s linear;
  }
  &.alt-toggle-on {
    border-color: #48b57b;
    .alt-toggle-inner {
      position: absolute;
      top: 1px;
      left: calc(100% - 18px - 1px);
      background: #48b57b;
    }
  }
  &.alt-toggle-disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
