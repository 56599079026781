.alt-page-wrapper {
  .dash-main.clusters {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    height: 100%;

    .list-header {
      flex: 0 0;
      position: relative;
      padding: 0 20px;
      padding-top: 15px;
      padding-bottom: 8px;
      border-bottom: 1px solid #d6d6d6;
      background: white;

      .list-title {
        padding: 10px 0;
        font: 600 20px/1 'TradeGothic', 'Helvetica Neue', Helvetica, Arial,
          sans-serif;
      }

      .list-header-bar {
        display: flex;
        flex-direction: row;

        .alt-refresh-button {
          i.fa {
            position: relative;
            left: -4px;
            font-size: 14px;
          }
          &.alt-button-disabled {
            i.fa {
              animation: spinner 0.8s linear infinite;

              @keyframes spinner {
                to {
                  transform: rotate(360deg);
                }
              }
            }
          }
        }
      }
    }
    .dash-main-list {
      flex: 1 1;
      position: relative;
      width: 100%;
      background: white;

      .alt-clusters-list-table {
        .alt-table {
          .rt-table {
            .rt-thead {
              .rt-tr {
                padding-left: 12px;
              }
            }
            .rt-tbody {
              .rt-tr-group {
                .rt-tr {
                  padding-left: 12px;
                }
              }
            }
          }
        }
      }
      .alt-airflow-link {
        text-decoration: underline;
        width: 300px;

        &:not(:first-child) {
          margin-left: 10px;
        }
        &.alt-link-disabled {
          cursor: not-allowed;
          color: gray;
          text-decoration: none;
        }
      }
      .alt-c-health {
        img {
          position: relative;
          display: inline-block;
          height: 12px;
          top: 2px;
          margin-right: 5px;
        }
      }
      .alt-cluster-label {
        position: relative;
        display: inline-block;
        padding-right: 5px;
        border-radius: 3px;
        border: 1px solid lightgray;
        font: 500 12px/14px sans-serif;

        &:not(:last-child) {
          margin-right: 5px;
        }

        .alt-cluster-label-key {
          position: relative;
          margin-right: 7px;
          padding-right: 5px;
          padding-left: 5px;
          background: lightgray;

          &:after {
            content: '';
            position: absolute;
            top: 0;
            right: -4px;
            border-top: 14px solid lightgray;
            border-right: 4px solid transparent;
          }
        }
      }
      .alt-cluster-member {
        position: relative;
        display: inline-block;
        padding-right: 5px;
        border-radius: 3px;
        border: 1px solid lightgray;
        font: 500 12px/14px sans-serif;

        &:not(:last-child) {
          margin-right: 5px;
        }
        .fa {
          font-size: 11px;
          padding: 0 3px;
          color: gray;
        }
      }
    }
  }

  .vc-icon {
    padding-right: 3px;
    color: #fc6100;
  }
}
