.alt-search {
  display: block;
  position: relative;
  flex: 0 1 320px;
  height: 32px;
  margin-right: auto;
  border-radius: 5px;
  border: 1px solid lightgray;

  .fa-search {
    position: absolute;
    top: 0;
    right: 10px;
    font-size: 14px;
    line-height: 30px;
    color: lightgray;
  }
  input {
    width: 100%;
    height: 30px;
    padding-left: 10px;
    border: none;
    font: 500 14px/30px sans-serif;
    background: transparent;
  }
}
