.section-members {
  .split-group:nth-child(1) {
    .tooltip {
      margin-top: 27px;
    }
  }

  .split-group {
    > :nth-child(1) {
      flex: 0 0 90px;

      .alt-select {
        min-width: 90px;

        .select__control {
          border-right: none;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }

    > :nth-child(2) {
      flex: 1 1;

      .alt-select {
        .select__control {
          border-radius: 0;
        }
      }

      .alt-select {
        .select__control {
          border-radius: 0;
        }
      }

      .alt-text-input {
        border-radius: 0;

        input {
          border-radius: 0;
          padding: 7px 12px;
        }
      }
    }

    > :nth-child(3) {
      flex: 0 0 100px;

      .alt-select {
        min-width: 110px;

        .select__control {
          border-radius: 0;
          border-left: none;
          border-right: none;
        }
      }
    }

    .tooltip {
      height: 32px;
    }
  }

  .form-info {
    position: relative;
    display: block;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 3px;
    border: 1px solid lightgray;
    border-left: 3px solid #3498db;
    padding: 6px 12px;
    font: 400 16px/1.4 sans-serif;

    .fa {
      padding-right: 8px;
      color: #3498db;
    }
  }

  .user-access-description {
    max-width: 660px;
    width: 100%;

    .alt-form-error {
      margin-top: 10px;
    }
  }

  .button-row {
    margin-top: 10px;
  }
}

.ad-group-field {
  .ad-group-option {
    position: relative;
    padding: 8px 10px;

    .fa {
      position: absolute;
      top: 0;
      right: 8px;
      line-height: 30px;
      font-size: 10px;
      color: #b7b7b7;
    }
  }

  // react-select override
  .alt-select {
    .select__control {
      .ad-group-option {
        padding: 8px 0;

        .fa {
          right: 3px;
        }
      }

      .select__value-container {
        padding: 0 8px;

        .select__single-value {
          width: 100%;
        }
      }
    }

    .select__menu {
      .select__menu-list {
        .select__option {
          padding: 0;
        }
      }
    }
  }
}