.alt-spinner {
  position: relative;
  margin: 25px 40px;
  border-radius: 50%;
  border: 1px solid lightgray;
  border-top: 1px solid black;
  animation: alt-spinner 0.6s linear infinite;
  height: 16px;
  width: 16px;

  @keyframes alt-spinner {
    to {
      transform: rotate(360deg);
    }
  }
}
